import type { App } from '@flipdish/api-client-typescript';
import type { RedirectProps, RouteProps } from 'react-router';

import AppStoreRoutes from '../components/AppStore/AppStore.routes';
import AuditLogsPage from '../components/AuditLogs/AuditLogsPage';
import { AuditLogDetails } from '../components/AuditLogs/Details/AuditLogDetails';
import BillingRoutes from '../components/Billing/Billing.routes';
import CampaignsRoutes from '../components/Campaigns/routes';
import Customers from '../components/Customers/Customers.routes';
import DevelopersRoutes from '../components/Developers/routes';
import DeviceRoutes from '../components/Devices/Devices.routes';
import EntitlementsRoutes from '../components/Entitlements/entitlements.routes';
import BankingRoutes from '../components/Finance/Banking.routes';
import FinanceRoutes from '../components/Finance/Finance.routes';
import HomeRoutes from '../components/Home/routes';
import KioskRoutes from '../components/Kiosks/Kiosks.routes';
import MenuEditorRoutes from '../components/MenuEditor/MenuEditor.routes';
import MenuRoutes from '../components/Menus/Menu.routes';
import MicroFrontendExampleRoutes from '../components/MicroFrontendExample/MicroFrontendExample.routes';
import OrdersRoutes from '../components/Order/routes';
import OrderFulfillmentStatusConfigsRoutes from '../components/OrderFulfillmentStatus/OrderFulfillmentStatus.routes';
import ModifierGroupRoutes from '../components/ProductManagement/ModifierGroups/modifierGroup.routes';
import ProductRoutes from '../components/ProductManagement/Products/product.routes';
import Profile from '../components/Profile/Profile';
import PropertiesRoutes from '../components/Properties/properties.routes';
import PushNotificationsRoutes from '../components/PushNotifications/routes';
import ReportRoutes from '../components/Reports/routes';
import AccountsRoutes from '../components/RMS/Accounts/Accounts.routes';
import DocumentsRoutes from '../components/RMS/Documents/documents.routes';
import FoodSafetyRoutes from '../components/RMS/FoodSafety/FoodSafety.routes';
import InventoryRoutes from '../components/RMS/Inventory/Inventory.routes';
import PayrollRoutes from '../components/RMS/Payroll/Payroll.routes';
import SalesRoutes from '../components/RMS/Sales/sales.routes';
import SalesChannelsRoutes from '../components/SalesChannels/routes';
import SettingsRoutes from '../components/Settings/settings.routes';
import StoreGroupRoutes from '../components/StoreGroups/StoreGroup.routes';
import { Teammates } from '../components/Teammates/Teammates';
import TelephonyLooker from '../components/Telephony/TelephonyLooker';
import VoucherRoutes from '../components/Vouchers/routes';
import OldWebsiteAndAppRoutes from '../components/WebsiteAndApp/oldRoutes';
import type { RouteNames } from './routeNames';

export const optionalRoutes = {
  isPushNotificationsAvailable: ({ HasIosApp, HasAndroidApp }: App) => HasAndroidApp || HasIosApp,
};

//#region TEMPORARY!
// the root routing in AuthRouter should be used/extended
declare global {
  interface PortalRoutePropsExtended extends RouteProps {
    /** Unique name `{module}.{route}`
     * @example
     * "settings.general"
     * */
    name: RouteNames;
    /** @deprecated if not used should be removed */
    label: string;
    /** @deprecated if not used should be removed */
    title: string;
    key?: string;
    permissions?: AppState['permissions'];
  }
  interface PortalRedirectPropsExtended extends RedirectProps {
    /** Unique name `{module}.{route}`
     * @example
     * "settings.general"
     * */
    name: RouteNames;
    key?: string;
  }
}
//#endregion

export const portalRoutes = (
  appId: string,
  match?
): (PortalRoutePropsExtended | PortalRedirectPropsExtended)[] => [
  // #region finance
  {
    name: 'finance',
    path: '/:appId/finance',
    label: 'Finance',
    title: 'Finance',
    component: FinanceRoutes,
    key: appId,
    permissions: ['ViewBankAccounts', 'FlipdishStaff', 'Onboarding'],
  },
  // #endregion
  // #region billing
  {
    name: 'billing',
    path: '/:appId/billing',
    label: 'Billing',
    title: 'Billing',
    component: BillingRoutes,
    key: appId,
    permissions: [
      'Owner',
      'StoreOwner',
      'StoreManager',
      'ManagedOwner',
      'FinanceManger',
      'FlipdishStaff',
    ],
  },
  // #endregion
  // #region old banking
  {
    name: 'banking',
    path: '/:appId/banking',
    label: 'Banking',
    title: 'Banking',
    component: BankingRoutes,
    key: appId,
    permissions: ['ViewBankAccounts', 'FlipdishStaff'],
  },
  // #endregion

  // #region home
  {
    name: 'home',
    path: '/:appId/home',
    label: 'Home',
    title: 'Home',
    component: HomeRoutes,
    key: appId,
  },
  // #endregion

  // #region orders
  {
    name: 'orders',
    path: '/:appId/orders',
    label: 'Orders',
    title: 'Orders',
    component: OrdersRoutes,
  },
  // #endregion

  // #region Sales
  {
    name: 'sales',
    path: '/:appId/sales',
    label: 'Sales',
    title: 'Sales',
    component: SalesRoutes,
  },
  // #endregion

  // #region Properties
  {
    name: 'properties',
    path: '/:appId/properties',
    label: 'Properties',
    title: 'Properties',
    component: PropertiesRoutes,
  },
  // #endregion

  // #region menu
  {
    name: 'menus',
    path: '/:appId/menus',
    label: 'Menus',
    title: 'Menus',
    component: MenuRoutes,
    permissions: ['ViewMenu'],
  },
  {
    name: 'menu_editor',
    path: '/:appId/menu-editor',
    label: 'Menu Editor',
    title: 'Menu Editor',
    component: MenuEditorRoutes,
    permissions: ['Owner', 'ManagedOwner', 'FlipdishStaff'],
  },
  // #endregion
  // #region RMS modules
  {
    name: 'inventory',
    path: '/:appId/add-ons/inventory',
    label: 'Inventory',
    title: 'Inventory',
    component: InventoryRoutes,
    permissions: ['Owner', 'ManagedOwner', 'FlipdishStaff'],
  },
  {
    name: 'documents',
    path: '/:appId/add-ons/documents',
    label: 'Documents',
    title: 'Documents',
    component: DocumentsRoutes,
    permissions: ['Owner', 'ManagedOwner', 'FlipdishStaff'],
  },
  {
    name: 'accounts',
    path: '/:appId/add-ons/accounts',
    label: 'Accounts',
    title: 'Accounts',
    component: AccountsRoutes,
    permissions: ['Owner', 'ManagedOwner', 'FlipdishStaff'],
  },
  {
    name: 'payroll',
    path: '/:appId/add-ons/payroll',
    label: 'Payroll',
    title: 'Payroll',
    component: PayrollRoutes,
    permissions: ['Owner', 'ManagedOwner', 'FlipdishStaff'],
  },
  {
    name: 'foodSafety',
    path: '/:appId/add-ons/food-safety',
    label: 'FoodSafety',
    title: 'FoodSafety',
    component: FoodSafetyRoutes,
    permissions: ['Owner', 'ManagedOwner', 'FlipdishStaff'],
  },
  // #endregion
  // #region micro-frontend-example
  {
    name: 'micro-frontend-example',
    path: '/:appId/micro-frontend-example',
    label: 'Micro Frontend Example',
    title: 'Micro Frontend Example',
    component: MicroFrontendExampleRoutes,
    permissions: ['FlipdishStaff'],
  },
  // #endregion

  // #region product
  {
    name: 'products',
    path: '/:appId/products',
    label: 'Products',
    title: 'Products',
    component: ProductRoutes,
  },
  // #endregion

  // #region modifier-groups
  {
    name: 'modifier-groups',
    path: '/:appId/modifier-groups',
    label: 'Modifier_Groups',
    title: 'Modifier_Groups',
    component: ModifierGroupRoutes,
  },
  // #endregion

  // #region profile
  {
    name: 'profile',
    path: '/:appId/profile',
    label: 'My Account',
    title: 'Account',
    component: Profile,
  },
  // #endregion

  // #region stores
  {
    name: 'storegroups',
    path: '/:appId/storegroups',
    label: 'Stores',
    title: 'Stores',
    component: StoreGroupRoutes,
    // FIXME: refactoring routing coz now its just getting out of hand
    key: match && match.params ? match.params.appId : appId,
  },
  // #endregion

  // #region teammates
  {
    name: 'teammates',
    path: '/:appId/teammates',
    label: 'Teammates',
    title: 'Teammates',
    component: Teammates,
    permissions: ['EditTeammates'],
  },
  // #endregion

  // #region telephony
  {
    name: 'telephony',
    path: '/:appId/telephony/dashboards/global/:id',
    label: 'Telephony',
    title: 'Telephony',
    component: TelephonyLooker,
    permissions: ['ViewApmStatistics'],
    key: appId,
  },
  // #endregion

  // #region vouchers
  {
    name: 'vouchers',
    path: '/:appId/vouchers',
    label: 'Vouchers',
    title: 'Vouchers',
    component: VoucherRoutes,
    permissions: ['ViewVouchers'],
    key: appId,
  },
  // #endregion

  // #region campaigns
  {
    name: 'campaigns',
    path: '/:appId/campaigns',
    label: 'Campaigns',
    title: 'Campaigns',
    component: CampaignsRoutes,
    permissions: ['ViewCampaignsConfigurations'],
    key: appId,
  },
  // #endregion

  // #region logs
  {
    name: 'logs',
    path: '/:appId/logs',
    label: 'Audit Log',
    title: 'Audit Log',
    component: AuditLogsPage,
    exact: true,
    key: appId,
  },
  {
    name: 'logs.id',
    path: '/:appId/logs/:id',
    label: 'Audit Log Details',
    title: 'Audit Log Details',
    component: AuditLogDetails,
    key: appId,
  },
  // #endregion

  // #region developers
  {
    name: 'developers',
    path: '/:appId/developers',
    label: 'Developers Section',
    title: 'Developers Section',
    component: DevelopersRoutes,
  },
  // #endregion

  // #region sales channels
  {
    name: 'sales-channels',
    path: '/:appId/sales-channels',
    label: 'Sales channels',
    title: 'Sales channels',
    component: SalesChannelsRoutes,
    permissions: ['ViewSalesChannels', 'EditSalesChannels'],
  },

  {
    name: 'website',
    path: '/:appId/website',
    label: 'Website & App',
    title: 'Website & App',
    component: OldWebsiteAndAppRoutes,
    permissions: ['Owner'],
  },
  // #endregion

  // #region push notifications
  {
    name: 'push_notifications',
    path: '/:appId/push_notifications',
    label: 'Push Notifications',
    title: 'Push Notifications',
    component: PushNotificationsRoutes,
    permissions: ['SendPushNotificationToCustomer'],
    key: appId,
  },
  // #endregion

  // #region Terminals
  {
    name: 'terminals',
    path: '/:appId/terminals',
    label: 'Terminals',
    title: 'Terminals',
    component: DeviceRoutes,
    permissions: ['ViewHydraConfig'],
  },
  // #endregion

  // #region kiosks
  {
    name: 'kiosks',
    path: '/:appId/kiosks',
    label: 'Kiosks',
    title: 'Kiosks',
    component: KioskRoutes,
    permissions: ['ViewHydraConfig'],
  },
  // #endregion

  // #region appstore
  {
    name: 'appstore',
    path: '/:appId/appstore',
    label: 'AppStore',
    title: 'AppStore',
    component: AppStoreRoutes,
  },
  // #endregion

  // #region orderFulfillmentStatus
  {
    name: 'orderFulfillmentStatus',
    path: '/:appId/orderFulfillmentStatus',
    label: 'orderFulfillmentStatus',
    title: 'orderFulfillmentStatus',
    component: OrderFulfillmentStatusConfigsRoutes,
  },
  // #endregion

  // #region reports
  {
    name: 'reports',
    path: '/:appId/reports',
    component: ReportRoutes,
    label: 'Reports',
    title: 'Reports',
    permissions: ['ViewSalesEndOfDayStatistics'],
    key: appId,
  },
  // #endregion
  // #region customers
  {
    name: 'reports.customers',
    path: '/:appId/customers',
    component: Customers,
    label: 'Customers',
    title: 'Customers',
    permissions: ['ViewCustomerStatistics'],
    key: appId,
  },
  //#endregion

  // #region settings
  {
    name: 'settings',
    path: '/:appId/settings',
    label: 'Settings',
    title: 'Settings',
    component: SettingsRoutes,
    key: appId,
    permissions: ['Owner', 'ManagedOwner'],
  },
  // #endregion

  // #region entitlements
  {
    name: 'entitlements',
    path: '/:appId/entitlements',
    label: 'Entitlements',
    title: 'Entitlements',
    component: EntitlementsRoutes,
    key: appId,
    permissions: ['FlipdishStaff'],
  },
  // #endregion

  {
    name: 'portal.redirect_index',
    from: '*',
    to: `/${appId}/home`,
  },
];
