import * as React from 'react';
import { useState } from 'react';

import type * as APIClient from '@flipdish/api-client-typescript';
import DeveloperIcon from '@mui/icons-material/CodeOutlined';
import LogOutIcon from '@mui/icons-material/MeetingRoomOutlined';
import ProfileIcon from '@mui/icons-material/PersonOutline';
import UpdateIcon from '@mui/icons-material/Update';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import type { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles, { type WithStyles } from '@mui/styles/withStyles';
import { getActiveLanguage, getTranslate, setActiveLanguage } from 'react-localize-redux';
import { type DispatchProp, connect } from 'react-redux';
import Permissions, { add, remove } from 'react-redux-permissions';
import { Link } from 'react-router-dom';
import { compose } from 'redux';

import { accountActions } from '../actions/account.actions';
import constants from '../constants.json';
import { displayName, gravatarUrl, initials } from '../helpers/account';
import { accountRoutesConst as routes } from '../routes/account.routes';
import { isSubscriptionsBillingEnabled } from '../selectors/app.selector';
import DevLongLanguageToggle from './DevLongLanguageToggle';
import HiddenFeatureToggle from './HiddenFeatureToggle';

const styles = (theme: Theme) => {
  return createStyles({
    holder: {
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
    },
    unselectable: {
      tabIndex: -1,
      outline: 0,
    },
    blank: {
      color: 'inherit',
      textDecoration: 'none',
    },
    languageIcon: {
      marginRight: theme.spacing(1),
    },
    menu: {
      zIndex: 1400,
    },
    avatar: {
      borderRadius: '10%',
    },
    name: {
      color: 'rgba(0, 0, 0, 0.6)',
      fontSize: '16px',
      fontWeight: 'normal',
      fontStyle: 'normal',
      fontStretch: 'normal',
      lineHeight: '1.5',
      letterSpacing: '0.2px',
    },
    personalDetails: {
      paddingLeft: theme.spacing(2),
    },
  });
};

type ProfileMenuProps = {
  account: APIClient.AccountDetail;
  currentApp: APIClient.App;
  dispatch: (input) => void;
  translate: (input: string) => string;
  activeLanguage: string;
  showSubscriptions: boolean;
};

export type Props = ProfileMenuProps & WithStyles<typeof styles> & DispatchProp<Action>;

const ProfileMenu = (props: Props) => {
  const [anchor, setAnchor] = useState<Element>();

  const handleHiddenFeatureChange = (action, role) => {
    const { dispatch, account } = props;
    if (action === 'add') {
      dispatch(add(role));
      dispatch(accountActions.update({ ...account, ShowHiddenFeatures: true }));
    } else {
      dispatch(remove(role));
      dispatch(accountActions.update({ ...account, ShowHiddenFeatures: false }));
    }
  };

  const handleDevLangChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const activeLanguage = event.target.checked ? 'dev-lang' : 'en';
    props.dispatch(setActiveLanguage(activeLanguage));
  };

  const handleMenu = (event: React.MouseEvent) => {
    setAnchor(event.currentTarget);
  };

  const handleClose = () => {
    setAnchor(undefined);
  };

  const { classes, account, currentApp, translate, showSubscriptions } = props;
  const appId = currentApp ? currentApp.AppId : 'global';

  const avatarImageUrl = gravatarUrl(account);
  const open = Boolean(anchor);

  return (
    <React.Fragment>
      <div
        aria-owns={open ? 'menu-appbar' : undefined}
        aria-haspopup="true"
        onClick={handleMenu}
        className={classes.holder}
        data-fd="profile-icon"
      >
        <IconButton color="inherit" data-fd="profile-menu-btn">
          <Avatar
            variant="square"
            alt={displayName(account)}
            src={avatarImageUrl}
            className={classes.avatar}
          >
            {initials(account)}
          </Avatar>
        </IconButton>
      </div>

      <Menu
        anchorEl={anchor}
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}
        className={classes.menu}
        id="menu-appbar"
        onClose={handleClose}
        open={open}
        transformOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}
      >
        <ListItem className={classes.unselectable}>
          <Avatar src={avatarImageUrl} />
          <ListItemText
            className={classes.personalDetails}
            primary={displayName(account)}
            secondary={`${account.Email}`}
            data-fd="profile-name"
          />
        </ListItem>
        <Permissions except={['Onboarding']}>
          <ListItemButton
            className={classes.blank}
            component={Link}
            data-fd="profile-account"
            onClick={handleClose}
            to={`/${appId}/profile`}
          >
            <ListItemIcon>
              <ProfileIcon />
            </ListItemIcon>
            <ListItemText primary={translate('My_account')} />
          </ListItemButton>
        </Permissions>
        <Permissions
          allowed={[
            'Owner',
            'StoreOwner',
            'StoreManager',
            'ManagedOwner',
            'FinanceManger',
            'FlipdishStaff',
          ]}
        >
          {appId !== 'global' && appId !== 'flipdish-global' && showSubscriptions ? (
            <ListItemButton
              className={classes.blank}
              component={Link}
              data-fd="my-subscriptions"
              onClick={handleClose}
              to={`/${appId}/billing/subscriptions`}
            >
              <ListItemIcon>
                <UpdateIcon />
              </ListItemIcon>
              <ListItemText primary={translate('My_subscriptions')} />
            </ListItemButton>
          ) : null}
        </Permissions>
        <Permissions except={['Onboarding']}>
          <ListItemButton
            className={classes.blank}
            component={Link}
            to={`/${appId}/developers`}
            data-fd="menu-link-developers"
            onClick={handleClose}
          >
            <ListItemIcon>
              <DeveloperIcon />
            </ListItemIcon>
            <ListItemText primary={translate('developer_tools')} />
          </ListItemButton>
        </Permissions>
        <ListItemButton
          className={classes.blank}
          component={Link}
          data-fd="profile-logout"
          onClick={handleClose}
          to={routes.Logout}
        >
          <ListItemIcon onClick={handleClose} style={{ minWidth: '56px' }}>
            <LogOutIcon />
          </ListItemIcon>
          <ListItemText primary={translate('Logout')} />
        </ListItemButton>
        <Permissions allowed={['FlipdishStaff']}>
          {constants.GIT_BRANCH !== 'master' ? (
            <MenuItem>
              <DevLongLanguageToggle
                checked={props.activeLanguage === 'dev-lang'}
                handleDevLangChange={handleDevLangChange}
              />
            </MenuItem>
          ) : null}
          <MenuItem>
            <HiddenFeatureToggle
              checked={account.ShowHiddenFeatures}
              handleHiddenFeatureChange={handleHiddenFeatureChange}
            />
          </MenuItem>
        </Permissions>
      </Menu>
    </React.Fragment>
  );
};

function mapStateToProps(state: AppState) {
  const { account, currentApp, locale } = state;
  return {
    account,
    currentApp,
    translate: getTranslate(locale),
    activeLanguage: getActiveLanguage(locale),
    showSubscriptions: isSubscriptionsBillingEnabled(state),
  };
}

const EnhancedComponent = compose(
  withStyles(styles, {
    name: 'ProfileMenu',
  }),
  connect(mapStateToProps)
)(ProfileMenu);

export { EnhancedComponent as ProfileMenu };
