// Full list of Zeus error codes: https://github.com/flipdishbytes/zeus/blob/79b9839d1b61257584a0a10f24bbbe802837615f/FlipdishWeb/Flipdish.Shared.ResultPattern/ErrorType.cs#L4
export enum ErrorCodes {
  UNKNOWN_ERROR = 99,
}

export interface ServerError {
  code: number | null;
  message: string;
  stack: string | null;
}

export const mapServerError = async (incomingMessage): Promise<ServerError> => {
  const defaultErrorMessage = 'An_error_occurred';
  const formatError = (errorData): ServerError => {
    const code = errorData?.ErrorCode || errorData?.errorCode || errorData?.code || null;
    const message = errorData?.Message || errorData?.message || defaultErrorMessage;
    const stack = errorData?.StackTrace || errorData?.stackTrace || null;

    // If the error code is unknown (unhandled exception on BE), return a default error message
    if (code === ErrorCodes.UNKNOWN_ERROR) {
      return {
        code,
        message: defaultErrorMessage,
        stack,
      };
    }

    return {
      code,
      message,
      stack,
    };
  };

  if (incomingMessage instanceof Response && !incomingMessage.ok) {
    const errorData = await incomingMessage.json();
    return formatError(errorData);
  }

  // RMS error mapping
  if (incomingMessage?.response?.data?.error) {
    return formatError(incomingMessage.response.data.error);
  }

  if (incomingMessage.hasOwnProperty('message')) {
    return formatError(incomingMessage);
  }

  return {
    code: null,
    message: defaultErrorMessage,
    stack: null,
  };
};

export const mapServerErrorReject = (incomingMessage) =>
  Promise.reject(mapServerError(incomingMessage));

/** The generated typescript-fetch api is trying to parse the response body as JSON, but it's empty. There is a bug in the generated code and also Zeus should be sending 204 No Content instead of just 200 */
export const KNOWN_ERRORS = [
  'JSON.parse: unexpected end of data at line 1 column 1 of the JSON data',
  'Unexpected end of JSON input',
];
