import type * as APIClient from '@flipdish/api-client-typescript';
import { Md5 } from 'ts-md5';

const leadingChar = (s: string) => s.charAt(0).toUpperCase();
const userName = (email: string) => email.split('@')[0];
const initialize = (s: string) => s.split(/[ ._]/).map(leadingChar).join('').slice(0, 2);

export function initials(account: APIClient.AccountDetail): string {
  try {
    if (account.Name) {
      return initialize(account.Name);
    }

    if (account.Email) {
      return initialize(userName(account.Email));
    }

    return '';
  } catch (e) {
    return '';
  }
}

export function displayName(account: APIClient.AccountDetail) {
  try {
    if (account.Name) {
      if (account.Name.length > 47) {
        return `${account.Name.substring(0, 47)}…`;
      }
      return account.Name;
    }
    return '';
  } catch (e) {
    return '';
  }
}

export function gravatarUrl(account: APIClient.AccountDetail): string {
  const hash = Md5.hashStr(account.Email || '');
  return `https://www.gravatar.com/avatar/${hash}?d=404`;
}
