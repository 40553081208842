import React, { ChangeEvent, useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import { type Theme, useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { useMutation, useQuery } from '@tanstack/react-query';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { SkeletonLoader } from '@fd/ui/atoms/SkeletonLoader';
import ErrorComponent from '@fd/ui/ErrorComponent';
import LoadingButton from '@fd/ui/LoadingButton';
import { DescriptiveCard } from '@fd/ui/molecules/DescriptiveCard';
import TextField from '@fd/ui/TextField/TextField';

import { notifyError, NotifyProps } from '../../../../layouts/Notify/actions';
import { useTracking } from '../../../../services/amplitude/useTracking';
import { checkout, getCheckoutSummary } from '../../../../services/checkout.service';
import { renderCurrency } from '../../../Finance/Payouts/helpers';
import { translateInterval } from '../../helpers';
import ProductTable from './ProductTable';

const useStyles = makeStyles((theme: Theme) => ({
  spacingBottom: {
    marginBottom: theme.spacing(2),
  },
  spacingTop: {
    marginTop: theme.spacing(2),
  },
  detailsGrouping: {
    marginBottom: theme.spacing(1),
  },
  breakWord: {
    wordBreak: 'break-word',
  },
  bold: {
    fontWeight: 'bold',
  },
  italic: {
    fontStyle: 'italic',
  },
}));

export type CheckoutSummaryProps = {
  appId: string;
  priceId: string;
  addOns: string[] | undefined;
  subscriptionId: string;
  languageCode: string;
};

type Props = CheckoutSummaryProps & MappedDispatch & MappedState;

const CheckoutSummary = ({
  appId,
  priceId,
  addOns,
  subscriptionId,
  languageCode,
  dispatchNotifyError,
  returnPathOverride,
  translate,
}: Props) => {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const { trackEvent, trackNavigationEvent } = useTracking();
  const returnUrl = returnPathOverride
    ? `${returnPathOverride}?session_id={CHECKOUT_SESSION_ID}`
    : `/${appId}/billing/checkout/success?session_id=${subscriptionId}`;

  const [promoCode, setPromoCode] = useState<string>('');
  const [hasAppliedPromoCode, setHasAppliedPromoCode] = useState<boolean>(false);
  const {
    data: checkoutSummary,
    isPending,
    isError,
    error,
    isFetching,
    refetch: fetchCheckoutSummary,
  } = useQuery({
    queryKey: ['checkout-summary', appId, subscriptionId, priceId, addOns],
    queryFn: () =>
      getCheckoutSummary(
        appId,
        subscriptionId,
        priceId,
        addOns,
        promoCode.trim() !== '' ? promoCode.trim() : undefined
      ),
  });

  useEffect(() => {
    if (isError && error instanceof Error) {
      dispatchNotifyError({ message: error.message, translate: false });
    }
  }, [isError, error]);

  const {
    mutateAsync: checkoutMutation,
    isPending: isProcessingCheckout,
    data: checkoutResponse,
  } = useMutation({
    mutationFn: () => {
      return checkout(appId, subscriptionId, {
        newItems: [priceId],
        addOns,
        promoCode: promoCode.trim() !== '' ? promoCode.trim() : undefined,
      });
    },

    onError: (error: Error) => {
      dispatchNotifyError({ message: error.message, translate: false });
    },
  });

  useEffect(() => {
    if (!checkoutResponse || checkoutResponse !== true) {
      return;
    }

    trackNavigationEvent('Navigate to success page');
    history.push(returnUrl);
  }, [checkoutResponse, returnUrl]);

  useEffect(() => {
    if (!hasAppliedPromoCode) {
      fetchCheckoutSummary();
    }
  }, [hasAppliedPromoCode]);

  const handleSubscribeClick = async () => {
    trackEvent('Subscribe now button clicked');
    await checkoutMutation();
  };

  const handlePromoCodeApplyClick = async () => {
    setHasAppliedPromoCode(true);
    await fetchCheckoutSummary();
  };

  const handlePromoCodeRemoveClick = async () => {
    setHasAppliedPromoCode(false);
    setPromoCode('');
  };

  if (isPending) {
    return (
      <SkeletonLoader
        fdKey="checkout-summary-skeleton"
        rows={[
          { height: '80px', width: '100%' },
          { height: '80px', width: '100%' },
          { height: '80px', width: '100%' },
        ]}
      />
    );
  }

  if (!checkoutSummary) {
    return (
      <ErrorComponent title="Checkout_Invalid_Data" subtitle="Checkout_error_creating_summary" />
    );
  }

  const renderPaymentLine = (title: TranslationId, amount: number) => {
    return (
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="flex-start"
        spacing={2}
        data-fd={`${title}-row`}
      >
        <Typography variant="body1">{translate(title)}</Typography>
        <Typography variant="body1" className={classes.bold}>
          {renderCurrency(checkoutSummary.currency, languageCode, amount)}
        </Typography>
      </Stack>
    );
  };

  return (
    <Grid container spacing={6}>
      <Grid item xs={7}>
        <Stack direction="column" spacing={4}>
          <Box>
            <ProductTable
              currency={checkoutSummary.currency}
              items={checkoutSummary.existingItems}
              isLoading={false}
              title="Checkout_Current_products"
              interval={checkoutSummary.interval}
            />
          </Box>
          <Box>
            <ProductTable
              currency={checkoutSummary.currency}
              items={checkoutSummary.items}
              isLoading={false}
              title="Checkout_Additional_products"
              interval={checkoutSummary.interval}
            />
          </Box>
        </Stack>
      </Grid>
      <Grid item xs={5}>
        <Stack direction="column" spacing={2}>
          <DescriptiveCard isLoading={false} fdKey="payment_user_card">
            <Grid item xs={12}>
              <Typography variant="caption">{translate('Payment_method')}</Typography>
            </Grid>
            <Grid item xs={12} className={classes.detailsGrouping}>
              <Typography className={classes.breakWord} variant="body2">
                {checkoutSummary.paymentDescription}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="caption">{translate('User')}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography className={classes.breakWord} variant="body2">
                {checkoutSummary.subscriptionEmail}
              </Typography>
            </Grid>
          </DescriptiveCard>

          <DescriptiveCard fdKey="payment_discount_card">
            <Grid container>
              <Grid item xs={12}>
                <Box display="flex" justifyContent="space-between" alignItems="flex-end">
                  <TextField
                    fdKey="promo-code-input"
                    value={promoCode}
                    label={translate('Promo_code')}
                    disabled={hasAppliedPromoCode && checkoutSummary.discountAmount > 0}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      setPromoCode(e.target.value);
                    }}
                  />
                  {(isPending || isFetching || checkoutSummary.discountAmount === 0) && (
                    <Box>
                      <LoadingButton
                        variant="outlined"
                        loading={(!!promoCode || hasAppliedPromoCode) && (isPending || isFetching)}
                        fdKey="promo-code-apply-button"
                        onClick={handlePromoCodeApplyClick}
                        disabled={isPending || isFetching || isProcessingCheckout || !promoCode}
                      >
                        {translate('Apply')}
                      </LoadingButton>
                    </Box>
                  )}
                  {hasAppliedPromoCode && checkoutSummary.discountAmount > 0 && (
                    <Box>
                      <LoadingButton
                        variant="outlined"
                        loading={isPending || isFetching}
                        fdKey="promo-code-remove-button"
                        onClick={handlePromoCodeRemoveClick}
                        disabled={isPending || isFetching || isProcessingCheckout}
                      >
                        {translate('Remove')}
                      </LoadingButton>
                    </Box>
                  )}
                </Box>
              </Grid>
              {hasAppliedPromoCode && checkoutSummary.discountAmount > 0 && (
                <Grid item xs={12} className={classes.spacingTop}>
                  <Typography
                    variant="caption"
                    className={classes.italic}
                    color={theme.palette.success.main}
                  >
                    {translate('Successfully_applied_promo_code')}
                  </Typography>
                </Grid>
              )}
              {!(isPending || isFetching) &&
                hasAppliedPromoCode &&
                checkoutSummary.discountAmount === 0 && (
                  <Grid item xs={12} className={classes.spacingTop}>
                    <Typography
                      variant="caption"
                      className={classes.italic}
                      color={theme.palette.error.main}
                    >
                      {translate('Invalid_promo_code')}
                    </Typography>
                  </Grid>
                )}
            </Grid>
          </DescriptiveCard>

          <DescriptiveCard isLoading={false} fdKey="payment_information_card">
            <Grid item xs={12} className={classes.spacingBottom}>
              <Typography variant="caption">{`${translate('Pay')} Flipdish Ltd`}</Typography>
              <Typography variant="h4" className={classes.bold}>
                {renderCurrency(checkoutSummary.currency, languageCode, checkoutSummary.total)}
              </Typography>
              <Typography variant="caption" component="p">
                <>
                  {`${translate('Checkout_then')} `}
                  {renderCurrency(
                    checkoutSummary.currency,
                    languageCode,
                    checkoutSummary.promoIntervalCost ?? checkoutSummary.intervalCost
                  )}{' '}
                  {`${translate('Checkout_per')} `}
                  {translateInterval(checkoutSummary.interval, 'singular', 'lower')}
                  {checkoutSummary.promoDurationInMonths &&
                    ` ${(translate('Checkout_promo_interval_cost') as string).replace('{numMonths}', checkoutSummary.promoDurationInMonths.toString()).replace('{price}', renderCurrency(checkoutSummary.currency, languageCode, checkoutSummary.intervalCost))}`}
                </>
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Stack direction="column" spacing={1}>
                {renderPaymentLine('Subtotal', checkoutSummary.subtotal)}
                {!!checkoutSummary.discountAmount &&
                  renderPaymentLine('Discount_amount', -checkoutSummary.discountAmount)}
                {renderPaymentLine('Tax', checkoutSummary.tax)}
                <Divider />
                {renderPaymentLine('Checkout_Total_due_today', checkoutSummary.total)}
                <Box>
                  <Divider className={classes.spacingBottom} />
                </Box>
                <LoadingButton
                  variant="contained"
                  onClick={handleSubscribeClick}
                  type="button"
                  fdKey="subscribe-button"
                  size="large"
                  loading={isPending || isFetching || isProcessingCheckout}
                  fullWidth
                >
                  {translate('Subscribe_Now')}
                </LoadingButton>
              </Stack>
            </Grid>
          </DescriptiveCard>
        </Stack>
      </Grid>
    </Grid>
  );
};

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (dispatch: ThunkDispatch) => {
  return {
    dispatchNotifyError: (data: NotifyProps) => dispatch(notifyError(data)),
  };
};

type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => {
  const { checkout } = state;
  return {
    returnPathOverride: checkout.returnPath,
    translate: getTranslate(state),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutSummary);
