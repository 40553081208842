import React, { useEffect, useState } from 'react';

import {
  App,
  OrderFulfillmentStatusWithConfigurationActions,
} from '@flipdish/api-client-typescript';
import { type Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { useMutation } from '@tanstack/react-query';
import clsx from 'clsx';
import { Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import Permissions from 'react-redux-permissions';
import { withRouter } from 'react-router';
import { compose } from 'recompose';

import * as ordersActions from '../../../actions/order.action';
import {
  closeNotifySaving,
  notifyError,
  NotifyProps,
  notifySaved,
  notifySaving,
} from '../../../layouts/Notify/actions';
import { flagService } from '../../../services';
import { orderFulfillmentService } from '../../../services/order.service';
import { Button } from '../../../ui/atoms/Button';
import { SkeletonLoader } from '../../../ui/atoms/SkeletonLoader/SkeletonLoader';
import { SplitButton } from '../../../ui/atoms/SplitButton';
import { SplitText } from '../../../ui/atoms/SplitText';
import { OptionType } from '../../../ui/Select/Select';

const useStyles = makeStyles((theme: Theme) => ({
  // Skeleton loader
  skeletonCurrentStatus: {
    width: 200,
  },
  skeletonActionButton: {
    width: 150,
    float: 'right',
    marginRight: theme.spacing(2),
  },
  // Other
  button: {
    marginRight: theme.spacing(2),
  },
  singleButton: {
    backgroundColor: '#36d88e',
    color: '#fff',
    boxShadow: 'none',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    '&:hover': {
      backgroundColor: '#25c079',
    },
  },
  currentStatusContainer: {
    marginRight: 'auto',
  },
  statusName: {
    lineHeight: '28px',
    letterSpacing: '0.5px',
    color: theme.palette.text.primary,
  },
  caption: {
    color: 'rgba(0, 0, 0, 0.6)',
    marginBottom: '8px',
  },
}));

type InnerProps = MappedState & MappedDispatch;

type OuterProps = {
  orderId: number;
};

type Props = InnerProps & OuterProps;

const OrderFulfillmentStatus = (props: Props) => {
  const {
    orderId,
    getFulfillmentStusOrderById,
    orderFulfillmentStatus,
    orderFulfillmentStatusFailed,
    orderFulfillmentStatusLoading,
    showOrderFulfillmentStatusFlag,
    closeNotifyAsSaving,
    notifyAsError,
    notifyAsSaved,
    notifyAsSaving,
  } = props;
  const classes = useStyles();
  const [currentStatus, setCurrentStatus] = useState<OptionType | null>(null);
  const [nextDefaultOption, setNextDefaultOption] = useState<OptionType | null>(null);
  const [additionalOptions, setAdditionalOptions] = useState<OptionType[] | null>(null);

  useEffect(() => {
    showOrderFulfillmentStatusFlag && getFulfillmentStusOrderById(orderId);
  }, [orderId]);

  const handleCurrentStatus = () => {
    if (orderFulfillmentStatus.StatusName && orderFulfillmentStatus.StatusId) {
      setCurrentStatus({
        label: orderFulfillmentStatus.StatusName,
        value: orderFulfillmentStatus.StatusId,
      });
      return;
    }

    setCurrentStatus(null);
  };
  const handleDefaultNextStatus = () => {
    if (
      orderFulfillmentStatus.DefaultNextStatus &&
      orderFulfillmentStatus.NextStatuses != undefined &&
      orderFulfillmentStatus.NextStatuses.length > 0
    ) {
      const defaultOption = orderFulfillmentStatus.NextStatuses?.find(
        (status) => status.Id === orderFulfillmentStatus.DefaultNextStatus
      );

      if (defaultOption?.Name && defaultOption.Id) {
        setNextDefaultOption({
          label: defaultOption.Name,
          value: defaultOption.Id,
        });
      }
      return;
    }
    setNextDefaultOption(null);
  };
  const handleAdditionalOptions = () => {
    if (
      orderFulfillmentStatus.NextStatuses != undefined &&
      orderFulfillmentStatus.NextStatuses?.length > 1
    ) {
      const options = orderFulfillmentStatus.NextStatuses.filter(
        (status) => status.Id !== orderFulfillmentStatus.DefaultNextStatus
      ).map((status) => {
        return {
          label: status.Name!,
          value: status.Id!,
        };
      });

      setAdditionalOptions(options);
      return;
    }
    setAdditionalOptions(null);
  };

  useEffect(() => {
    if (!showOrderFulfillmentStatusFlag || !orderFulfillmentStatus) {
      return;
    }

    handleCurrentStatus();
    handleDefaultNextStatus();
    handleAdditionalOptions();
  }, [orderFulfillmentStatus]);

  const { mutate } = useMutation({
    mutationFn: async (selectedFulfillmentOption: string) => {
      notifyAsSaving();

      await orderFulfillmentService.updateOrderFulfillmentState(orderId, {
        StatusId: selectedFulfillmentOption,
      });
    },

    onSuccess: () => {
      closeNotifyAsSaving();
      notifyAsSaved();

      getFulfillmentStusOrderById(orderId);
    },

    onError: () => {
      closeNotifyAsSaving();
      notifyAsError({ message: 'Error updating fulfillment status' });
    },
  });

  const renderCurrentStatus = () => {
    if (orderFulfillmentStatusLoading && !orderFulfillmentStatus?.StatusName) {
      return (
        <SkeletonLoader
          fdKey={'skeleton-loader-current-status'}
          rows={[{ height: '46px', className: classes.skeletonCurrentStatus }]}
        />
      );
    }

    if (!currentStatus?.label) {
      return null;
    }

    return (
      <div className={classes.currentStatusContainer}>
        <Typography variant="caption" className={classes.caption}>
          <Translate id="Current_Status" />
        </Typography>

        <Typography data-fd="order-status-text" className={classes.statusName}>
          {currentStatus.label}
        </Typography>
      </div>
    );
  };

  const renderActionButton = () => {
    if (orderFulfillmentStatusLoading && !nextDefaultOption?.label) {
      return (
        <Permissions allowed={[App.AppResourceSetEnum.UpdateOrderFulfillmentStatus]}>
          <SkeletonLoader
            fdKey={'skeleton-loader-action-button'}
            rows={[{ height: '46px', className: classes.skeletonActionButton }]}
          />
        </Permissions>
      );
    }

    if (nextDefaultOption?.label && !additionalOptions) {
      return (
        <Permissions allowed={[App.AppResourceSetEnum.UpdateOrderFulfillmentStatus]}>
          <Button
            className={clsx(classes.button, classes.singleButton)}
            fdKey={nextDefaultOption.value as string}
            variant="primary"
            onClick={() => mutate(nextDefaultOption.value as string)}
          >
            <SplitText top={<Translate id="Mark_As" />} bottom={nextDefaultOption.label} />
          </Button>
        </Permissions>
      );
    }

    if (nextDefaultOption?.label && additionalOptions) {
      return (
        <Permissions allowed={[App.AppResourceSetEnum.UpdateOrderFulfillmentStatus]}>
          <SplitButton
            className={classes.button}
            dropdownOptions={additionalOptions}
            variant="green"
            defaultButtonOption={nextDefaultOption.value as string}
            onClick={mutate}
          >
            <SplitText top={<Translate id="Mark_As" />} bottom={nextDefaultOption.label} />
          </SplitButton>
        </Permissions>
      );
    }
  };

  return !orderFulfillmentStatusFailed ? (
    <>
      {renderCurrentStatus()}
      {renderActionButton()}
    </>
  ) : null;
};

type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => {
  const { orders } = state;
  return {
    orderFulfillmentStatus:
      orders.orderFulfillmentStatus as OrderFulfillmentStatusWithConfigurationActions,
    orderFulfillmentStatusFailed: orders.orderFulfillmentStatusFailed,
    orderFulfillmentStatusLoading: orders.orderFulfillmentStatusLoading,
    showOrderFulfillmentStatusFlag: flagService.isFlagOn(
      state,
      'orderFulfillmentStatusOrderDetails'
    ),
  };
};

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
  getFulfillmentStusOrderById: (id: number) =>
    dispatch(ordersActions.getFulfillmentStatusForOrderById(id)),
  closeNotifyAsSaving: () => dispatch(closeNotifySaving()),
  notifyAsError: (data: NotifyProps) => dispatch(notifyError(data)),
  notifyAsSaving: () => dispatch(notifySaving()),
  notifyAsSaved: () => dispatch(notifySaved()),
});

const EnhancedComponent = compose<Props, any>(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(OrderFulfillmentStatus);

export default EnhancedComponent;
