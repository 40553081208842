// #region setAcceptInviteFlowAsInProgress
const ACCEPT_INVITE_FLOW_IN_PROGRESS = 'ACCEPT_INVITE_FLOW_IN_PROGRESS';
export const setAcceptInviteFlowAsStarted = () => {
  sessionStorage.setItem(ACCEPT_INVITE_FLOW_IN_PROGRESS, 'true');
};

export const setAcceptInviteFlowAsCompleted = () => {
  sessionStorage.removeItem(ACCEPT_INVITE_FLOW_IN_PROGRESS);
};

export const isAcceptInviteFlowInProgress = (): boolean => {
  return !!sessionStorage.getItem(ACCEPT_INVITE_FLOW_IN_PROGRESS);
};
