import React from 'react';

import { type RouteComponentProps, Route, Switch } from 'react-router';

import ErrorBoundary from '../../layouts/Portal/ErrorBoundary';
import PropertiesList from './PropertiesList';

type Props = RouteComponentProps;

const PropertiesRoutes: React.FC<React.PropsWithChildren<Props>> = (props: Props) => {
  const {
    match: { path },
  } = props;
  return (
    <ErrorBoundary identifier="properties">
      <Switch>
        <Route exact path={`${path}`} component={PropertiesList} />
      </Switch>
    </ErrorBoundary>
  );
};

export default PropertiesRoutes;
