import React from 'react';

import { Translate } from 'react-localize-redux';
import Permissions from 'react-redux-permissions';
import { Route, RouteComponentProps, Switch, withRouter } from 'react-router';

import ErrorBoundary from '../../layouts/Portal/ErrorBoundary';
import LazyComponent from '../../ui/LazyComponent';
import RMSPage from '../RMS/components/RMSPage';
import OrganizationRoutes from '../RMS/Organization/Organization.routes';
import { RMSModules } from '../RMS/utils/rmsModules';
import { RMSSubscriptions } from '../RMS/utils/rmsSubscriptions';
import SalesChannelsGeneralSettings from '../SalesChannels/Settings/SalesChannelsGeneralSettings';
import EditTermsAndPolicyData from './Edit/EditTermsAndPolicyData';
import GeneralSettings from './GeneralSettings/GeneralSettings';
import { CreateMetafield } from './Metafields/pages/CreateMetafield';
import { EditMetafield } from './Metafields/pages/EditMetafield';
import { MetafieldDefinitions } from './Metafields/pages/MetafieldDefinitions';
import { MetafieldEntities } from './Metafields/pages/MetafieldEntities';
import PrintingTemplates from './PrintingTemplates/PrintingTemplates';
import SalesChannelsList from './SalesChannelsSettings/SalesChannelsList';

type Props = RouteComponentProps;

const entityRouteParams = 'catalog-item|catalog-group';

const SettingsRoutes: React.FC<React.PropsWithChildren<Props>> = (props: Props) => {
  const {
    match: { path },
  } = props;
  return (
    <ErrorBoundary identifier="settings">
      <Switch>
        <>
          <Route path={`${path}/organisation`} component={OrganizationRoutes} />

          <Route exact path={`${path}/brand`} component={GeneralSettings} />
          <Route exact path={`${path}/metafields`} component={MetafieldEntities} />
          <Route
            exact
            path={`${path}/metafields/:ownerEntity(${entityRouteParams})`}
            component={MetafieldDefinitions}
          />
          <Route
            exact
            path={`${path}/metafields/:ownerEntity/create`}
            component={CreateMetafield}
          />
          <Route
            exact
            path={`${path}/metafields/:ownerEntity/:definitionKey`}
            component={EditMetafield}
          />

          <Permissions allowed={['ViewSalesChannels', 'EditSalesChannels']}>
            <Route exact path={`${path}/sales-channels`} component={SalesChannelsList} />
          </Permissions>

          <Route
            exact
            path={`${path}/sales-channels/general-settings`}
            component={SalesChannelsGeneralSettings}
          />
          <Route exact path={`${path}/sales-channels/legal`} component={EditTermsAndPolicyData} />

          <Permissions allowed={['Owner', 'ManagedOwner', 'FlipdishStaff']}>
            <Route
              exact
              path={`${path}/sales-channels/printing-templates`}
              render={() => (
                <LazyComponent>
                  <PrintingTemplates />
                </LazyComponent>
              )}
            />
            <Route
              exact
              path={`${path}/sales-channels/printing-templates/receipt`}
              render={() => (
                <RMSPage
                  subscription={RMSSubscriptions.BASE}
                  title={<Translate id="Receipt_Templates" />}
                  module={RMSModules.RECEIPT_TEMPLATES}
                  url={`configurations/system/printingTemplates/${RMSModules.RECEIPT_TEMPLATES}`}
                />
              )}
            />
            <Route
              exact
              path={`${path}/sales-channels/printing-templates/kitchen-station`}
              render={() => (
                <RMSPage
                  subscription={RMSSubscriptions.BASE}
                  title={<Translate id="Kitchen_Station_Templates" />}
                  module={RMSModules.KITCHEN_STATION_TEMPLATES}
                  url={`configurations/system/printingTemplates/${RMSModules.KITCHEN_STATION_TEMPLATES}`}
                />
              )}
            />
            <Route
              exact
              path={`${path}/sales-channels/printing-templates/voucher`}
              render={() => (
                <RMSPage
                  subscription={RMSSubscriptions.BASE}
                  title={<Translate id="Voucher_Templates" />}
                  module={RMSModules.VOUCHER_TEMPLATES}
                  url={`configurations/system/printingTemplates/${RMSModules.VOUCHER_TEMPLATES}`}
                />
              )}
            />
            <Route
              exact
              path={`${path}/sales-channels/printing-templates/daily-summary-report`}
              render={() => (
                <RMSPage
                  subscription={RMSSubscriptions.BASE}
                  title={<Translate id="Daily_Summary_Report_Templates" />}
                  module={RMSModules.DAILY_SUMMARY_REPORT_TEMPLATES}
                  url={`configurations/system/printingTemplates/${RMSModules.DAILY_SUMMARY_REPORT_TEMPLATES}`}
                />
              )}
            />
          </Permissions>
        </>
      </Switch>
    </ErrorBoundary>
  );
};

export default withRouter(SettingsRoutes);
