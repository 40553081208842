import { Teammate } from '@flipdish/api-client-typescript';

import {
  CREATE_TEAMMATE_SUCCESS,
  DELETE_TEAMMATE_SUCCESS,
  GET_TEAMMATES_SUCCESS,
  GRANT_TEAMMATE_ACCESS_SUCCESS,
  SET_TEAMMATE_LOADING,
  TEAMMATE_DETAILS_LOAD_SUCCESS,
  UPDATE_TEAMMATE_REQUEST,
  UPDATE_TEAMMATE_SUCCESS,
} from '../actions/teammates.actions';
import { teammatesConstants } from '../constants/teammates.constants';
import { teammateEventConstants } from '../signalr/hub.events';

const teammatesInitialState = {
  teammate: undefined as Teammate | undefined,
  teammates: [] as Teammate[],
  teammatesTimestamp: null,
  teammateLoading: false,
};

export function teammates(state = teammatesInitialState, action) {
  switch (action.type) {
    case CREATE_TEAMMATE_SUCCESS:
    case GRANT_TEAMMATE_ACCESS_SUCCESS:
      return {
        ...state,
        teammates: [...state.teammates, action.payload],
        teammateLoading: false,
      };
    case teammatesConstants.SET_TEAMMATES_TIMESTAMP:
      return {
        ...state,
        teammatesTimestamp: action.payload,
      };
    case GET_TEAMMATES_SUCCESS:
      return {
        ...state,
        teammates: action.payload,
        teammatesTimestamp: null,
      };
    case DELETE_TEAMMATE_SUCCESS: {
      const filteredTeammates = state.teammates.filter((teammate) => {
        return (teammate as any).TeammateId != action.payload.TeammateId;
      });
      return {
        ...state,
        teammates: filteredTeammates,
      };
    }
    case SET_TEAMMATE_LOADING:
      return {
        ...state,
        teammateLoading: action.payload,
      };
    case UPDATE_TEAMMATE_SUCCESS: {
      const teamMateIndex = state.teammates.findIndex(
        (teammate) => teammate.TeammateId === action.payload.TeammateId
      );
      if (teamMateIndex > -1) {
        return {
          ...state,
          teammates: [
            ...state.teammates.slice(0, teamMateIndex),
            action.payload,
            ...state.teammates.slice(teamMateIndex + 1),
          ],
          teammateLoading: false,
        };
      }
      return {
        ...state,
        teammateLoading: false,
      };
    }
    case teammateEventConstants.INVITE_SENT:
      if (action.appId === action.payload.AppId) {
        const teamMateIndex = state.teammates.findIndex(
          (teammate) =>
            btoa((teammate as any).AppId + '|' + (teammate as any).Email) ===
            btoa(action.payload.Teammate.AppId + '|' + action.payload.Teammate.Email)
        );
        if (teamMateIndex === -1) {
          return {
            ...state,
            teammates: [...state.teammates, action.payload],
          };
        }
      }
      return state;
    case teammateEventConstants.INVITE_ACCEPTED:
      if (action.appId === action.payload.AppId) {
        const teamMateIndex = state.teammates.findIndex(
          (teammate) =>
            btoa((teammate as any).AppId + '|' + (teammate as any).Email) ===
            btoa(action.payload.Teammate.AppId + '|' + action.payload.Teammate.Email)
        );
        if (teamMateIndex > -1) {
          const modifiedTeammate = {
            ...(state.teammates[teamMateIndex] as any),
            InvitationStatus: Teammate.InvitationStatusEnum.Accepted,
          };

          return {
            ...state,
            teammates: [
              ...state.teammates.slice(0, teamMateIndex),
              modifiedTeammate,
              ...state.teammates.slice(teamMateIndex + 1),
            ],
          };
        }
      }
      return state;
    case teammateEventConstants.DELETED:
      if (action.appId === action.payload.AppId) {
        const filteredTeammates = state.teammates.filter((teammate) => {
          return (
            btoa((teammate as any).AppId + '|' + (teammate as any).Email) !=
            btoa(action.payload.Teammate.AppId + '|' + action.payload.Teammate.Email)
          );
        });
        return {
          ...state,
          teammates: filteredTeammates,
        };
      }
      return state;
    case teammateEventConstants.UPDATED:
      if (action.appId === action.payload.AppId) {
        const teamMateIndex = state.teammates.findIndex(
          (teammate) =>
            btoa(teammate.AppId + '|' + teammate.Email) ===
            btoa(action.payload.Teammate.AppId + '|' + action.payload.Teammate.Email)
        );
        if (teamMateIndex > -1) {
          return {
            ...state,
            teammates: [
              ...state.teammates.slice(0, teamMateIndex),
              action.payload.Teammate,
              ...state.teammates.slice(teamMateIndex + 1),
            ],
          };
        }
      }
      return state;

    case TEAMMATE_DETAILS_LOAD_SUCCESS:
      return {
        ...state,
        teammate: action.payload,
      };

    case UPDATE_TEAMMATE_REQUEST:
      return {
        ...state,
        teammateLoading: true,
      };
    default:
      return state;
  }
}
