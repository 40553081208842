import React from 'react';

import {
  CreateFulfillmentStatesConfiguration,
  FulfillmentStatesConfigurationSummary,
} from '@flipdish/api-client-typescript';
import Add from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { type Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { Translate, TranslateFunction } from 'react-localize-redux';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Typography } from '@fd/ui/atoms/Typography';
import PageLayout from '@fd/ui/Layout';
import { PageActionHeader } from '@fd/ui/molecules/PageActionHeader';
import { PageHeader } from '@fd/ui/molecules/PageHeader';

import { ReactComponent as MetafieldsIcon } from '../../../assets/images/metafields.svg';
import { notifyError, NotifyProps } from '../../../layouts/Notify/actions';
import { orderFulfillmentStatusService } from '../services/orderFulfillmentStatus.service';
import { OrderFulfillmentStatusTable } from './OrderFulfillmentStatusTable';

const useStyles = makeStyles((theme: Theme) => ({
  addDefinitionButton: {
    width: '100%',
  },
  container: {
    [theme.breakpoints.down('md')]: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
  },
  header: {
    display: 'flex',
  },
  listTitle: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
}));

type Props = OrderFulfillmentStatusListProps & MappedDispatch;

export type OrderFulfillmentStatusListProps = {
  limit: number;
  orderFulfillmentStatusConfigurations?: FulfillmentStatesConfigurationSummary[];
  total: number;
  appId: string;
  isLoading: boolean;
  translate: TranslateFunction;
};

const OrderFulfillmentStatusList = ({
  orderFulfillmentStatusConfigurations,
  appId,
  isLoading,
  notifyErrorAction,
  translate,
}: Props) => {
  const history = useHistory();

  const configName = `${translate('New_configuration') as string} ${
    orderFulfillmentStatusConfigurations ? orderFulfillmentStatusConfigurations.length + 1 : 1
  }`;

  const config = { Name: configName } as CreateFulfillmentStatesConfiguration;

  const handleCreateConfig = async (appId) => {
    try {
      const request = await orderFulfillmentStatusService.createOrderFulfillmentStatusConfig(
        appId,
        config
      );
      history.push(`/${appId}/orderFulfillmentStatus/${request.Data.ConfigurationUid}`);
    } catch (error) {
      notifyErrorAction({ message: error.message, translate: true });
    }
  };

  const classes = useStyles();

  return (
    <PageLayout
      auditLogsFilter={{ type: 'EventType', value: 'order.fulfillment.*' }}
      documentTitle="Order_Fulfillment_Status_Configurations"
      title={translate('Order_Fulfillment_Status_Configurations')}
      strictToParent
      toParent={`/${appId}/settings/sales-channels`}
    >
      <div className={classes.container}>
        <Grid container spacing={3}>
          <Grid className={classes.header} item xs={12} sm={7}>
            <PageHeader
              description={translate('Order_Fulfillment_Status_Configurations')}
              heading={translate('Order_Fulfillment_Status_Configurations')}
              icon={<MetafieldsIcon color="rgb(139,139,139)" />}
            />
          </Grid>
          <Grid className={classes.header} item xs={12} sm={5}>
            <PageActionHeader
              description={translate('Order_Fulfillment_Status_Configurations')}
              icon={<MetafieldsIcon color="#05149E" height={24} width={26.67} />}
            >
              <Button
                className={classes.addDefinitionButton}
                data-fd="create-metafield-definition-button"
                variant="contained"
                color="primary"
                onClick={() => handleCreateConfig(appId)}
              >
                <Add />
                <Translate id="Add_configuration" />
              </Button>
            </PageActionHeader>
          </Grid>
        </Grid>
        <div className={classes.listTitle}>
          <Typography>
            <Translate id="Order_Fulfillment_Status_Configurations" />
          </Typography>
        </div>
        <OrderFulfillmentStatusTable
          appId={appId}
          orderFulfillmentStatusConfigurations={orderFulfillmentStatusConfigurations}
          isLoading={isLoading}
          translate={translate}
        />
      </div>
    </PageLayout>
  );
};

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
  notifyErrorAction: (data: NotifyProps) => dispatch(notifyError(data)),
});

export default connect(null, mapDispatchToProps)(OrderFulfillmentStatusList);
