import { Teammate, TeammateBase } from '@flipdish/api-client-typescript';

import { teammatesConstants } from '../constants/teammates.constants';
import { notify, notifyError } from '../layouts/Notify/actions';
import { teammatesService } from '../services/teammates.service';
import { action, actionError } from './utils';

// #region getTeammates
export const GET_TEAMMATES_LOADING = 'GET_TEAMMATES_LOADING';
export const GET_TEAMMATES_REQUEST = 'GET_TEAMMATES_REQUEST';
export const GET_TEAMMATES_SUCCESS = 'GET_TEAMMATES_SUCCESS';
export const GET_TEAMMATES_FAILURE = 'GET_TEAMMATES_FAILURE';

export type GetTeammatesRequest = ReturnType<typeof getTeammatesRequest>;
export const getTeammatesRequest = () => action(GET_TEAMMATES_REQUEST);
export type GetTeammatesSuccess = ReturnType<typeof getTeammatesSuccess>;
export const getTeammatesSuccess = (props: Teammate[]) => action(GET_TEAMMATES_SUCCESS, props);
export type GetTeammatesFailure = ReturnType<typeof getTeammatesFailure>;
export const getTeammatesFailure = (error: Error, props) =>
  actionError(GET_TEAMMATES_FAILURE, props, error);

export const getTeammates = () => {
  return async (dispatch: ThunkDispatch, getState: () => AppState) => {
    const timestamp = Date.now();
    dispatch(action(teammatesConstants.SET_TEAMMATES_TIMESTAMP, timestamp));

    const { teammates, currentApp } = getState();
    const appId = currentApp.AppId as string;
    try {
      dispatch(action(SET_TEAMMATE_LOADING, true));
      dispatch(getTeammatesRequest());
      const result = await teammatesService.getTeammates(appId);
      dispatch(action(SET_TEAMMATE_LOADING, false));
      dispatch(getTeammatesSuccess(result));
      if (teammates.teammatesTimestamp === timestamp) {
        dispatch(action(teammatesConstants.SET_TEAMMATES_TIMESTAMP, timestamp));
        dispatch(action(GET_TEAMMATES_SUCCESS, result));
      }
    } catch (error) {
      if (teammates.teammatesTimestamp === timestamp) {
        dispatch(action(teammatesConstants.SET_TEAMMATES_TIMESTAMP, null));
      }
      dispatch(action(SET_TEAMMATE_LOADING, false));
      dispatch(getTeammatesFailure(error, appId));
    }
  };
};
// #endregion

// #region deleteTeammate
export const SET_TEAMMATE_LOADING = 'SET_TEAMMATE_LOADING';
export const DELETE_TEAMMATE_REQUEST = 'DELETE_TEAMMATE_REQUEST';
export const DELETE_TEAMMATE_SUCCESS = 'DELETE_TEAMMATE_SUCCESS';
export const DELETE_TEAMMATE_FAILURE = 'DELETE_TEAMMATE_FAILURE';

export const deleteTeammateRequest = () => action(DELETE_TEAMMATE_REQUEST);
export type DeleteTeammateSuccess = ReturnType<typeof deleteTeammateSuccess>;
export const deleteTeammateSuccess = (props: Teammate) => action(DELETE_TEAMMATE_SUCCESS, props);
export const deleteTeammateFailure = (error: Error, props) =>
  actionError(DELETE_TEAMMATE_FAILURE, props, error);

export const deleteTeammate = (teammateId: string, callback?: () => void) => {
  return async (dispatch: ThunkDispatch, getState: () => AppState) => {
    const { teammates, currentApp } = getState();
    const appId = currentApp.AppId as string;
    try {
      const teammate = teammates.teammates.find((teammate) => teammate.TeammateId === teammateId);
      if (teammate) {
        dispatch(action(SET_TEAMMATE_LOADING, true));
        await teammatesService.deleteTeammate(appId, teammateId);
        dispatch(deleteTeammateSuccess(teammate));
        dispatch(action(SET_TEAMMATE_LOADING, false));
        if (callback) {
          callback();
        }
        dispatch(
          notify({
            message: 'Removed_successfully',
            translate: true,
            variant: 'success',
            disableWindowBlurListener: true,
          })
        );
      }
    } catch (error) {
      dispatch(deleteTeammateFailure(error, appId));
      dispatch(action(SET_TEAMMATE_LOADING, false));
      dispatch(notifyError({ message: 'Removal_failed' }));
      throw error;
    }
  };
};
// #endregion

// #region createTeammate
export const CREATE_TEAMMATE_REQUEST = 'CREATE_TEAMMATE_REQUEST';
export const CREATE_TEAMMATE_SUCCESS = 'CREATE_TEAMMATE_SUCCESS';
export const CREATE_TEAMMATE_FAILURE = 'CREATE_TEAMMATE_FAILURE';

export const createTeammateRequest = () => action(CREATE_TEAMMATE_REQUEST);
export type CreateTeammateSuccess = ReturnType<typeof createTeammateSuccess>;
export const createTeammateSuccess = (props: Teammate) => action(CREATE_TEAMMATE_SUCCESS, props);
export const createTeammateFailure = (error: Error, props) =>
  actionError(CREATE_TEAMMATE_FAILURE, props, error);

export const createTeammate = (
  email: string,
  appAccessLevel: TeammateBase.AppAccessLevelEnum,
  storeIds: number[],
  callback?: () => void
) => {
  return async (dispatch: ThunkDispatch, getState: () => AppState) => {
    const { currentApp } = getState();
    const appId = currentApp.AppId as string;

    try {
      dispatch(action(SET_TEAMMATE_LOADING, true));
      const result = await teammatesService.createTeammate(appId, email, appAccessLevel, storeIds);
      dispatch(createTeammateSuccess(result));
      dispatch(action(SET_TEAMMATE_LOADING, false));
      if (callback) {
        callback();
      }
      dispatch(
        notify({
          message: 'Invitation_sent',
          translate: true,
          variant: 'success',
          disableWindowBlurListener: true,
        })
      );
    } catch (error) {
      dispatch(createTeammateFailure(error, appId));
      dispatch(action(SET_TEAMMATE_LOADING, false));
      dispatch(notifyError({ message: 'Invitation_failed' }));
      throw error;
    }
  };
};
// #endregion

// #region reinviteTeammate
export function reinviteTeammate(
  email: string,
  appAccessLevel: TeammateBase.AppAccessLevelEnum,
  storeIds: number[]
) {
  return (dispatch, getState: () => AppState) => {
    const appId = getState().currentApp.AppId;
    return teammatesService.createTeammate(appId as string, email, appAccessLevel, storeIds).then(
      () => {
        console.log('Invitation resent');
      },
      (error) => {
        console.log(error);
      }
    );
  };
}

// #endregion

export const GRANT_TEAMMATE_ACCESS_REQUEST = 'GRANT_TEAMMATE_ACCESS_REQUEST';
export const GRANT_TEAMMATE_ACCESS_SUCCESS = 'GRANT_TEAMMATE_ACCESS_SUCCESS';
export const GRANT_TEAMMATE_ACCESS_FAILURE = 'GRANT_TEAMMATE_ACCESS_FAILURE';

export const grantTeammateAccessRequest = () => action(GRANT_TEAMMATE_ACCESS_REQUEST);
export type GrantTeammateAccessSuccess = ReturnType<typeof grantTeammateAccessSuccess>;
export const grantTeammateAccessSuccess = (props: Teammate) =>
  action(GRANT_TEAMMATE_ACCESS_SUCCESS, props);
export const grantTeammateAccessFailure = (error: Error, props) =>
  actionError(GRANT_TEAMMATE_ACCESS_FAILURE, props, error);

export const grantTeammateAccess = (
  email: string,
  appAccessLevel: TeammateBase.AppAccessLevelEnum,
  storeIds: number[],
  callback?: () => void
) => {
  return async (dispatch: ThunkDispatch, getState: () => AppState) => {
    const { currentApp } = getState();
    const appId = currentApp.AppId as string;

    try {
      dispatch(action(SET_TEAMMATE_LOADING, true));
      const result = await teammatesService.grantTeammateAccess(
        appId,
        email,
        appAccessLevel,
        storeIds
      );
      dispatch(grantTeammateAccessSuccess(result));
      dispatch(action(SET_TEAMMATE_LOADING, false));
      if (callback) {
        callback();
      }
      dispatch(
        notify({
          message: 'Successfully_saved_changes',
          translate: true,
          variant: 'success',
          disableWindowBlurListener: true,
        })
      );
    } catch (error) {
      dispatch(grantTeammateAccessFailure(error, appId));
      dispatch(action(SET_TEAMMATE_LOADING, false));
      dispatch(notifyError({ message: error.message }));
      throw error;
    }
  };
};

// #region updateTeammateDetails
export const UPDATE_TEAMMATE_LOAD = 'UPDATE_TEAMMATE_LOAD';
export const UPDATE_TEAMMATE_REQUEST = 'UPDATE_TEAMMATE_REQUEST';
export const UPDATE_TEAMMATE_SUCCESS = 'UPDATE_TEAMMATE_SUCCESS';
export const UPDATE_TEAMMATE_FAILURE = 'UPDATE_TEAMMATE_FAILURE';

export const updateTeammateRequest = () => action(UPDATE_TEAMMATE_REQUEST);

export type UpdateTeammateSuccess = ReturnType<typeof updateTeammateSuccess>;
export const updateTeammateSuccess = (props: Teammate) => action(UPDATE_TEAMMATE_SUCCESS, props);

export const updateTeammateFailure = (error: Error, props) =>
  actionError(UPDATE_TEAMMATE_FAILURE, props, error);

export function updateTeammateDetails(
  teammateId: string,
  appAccessLevel: TeammateBase.AppAccessLevelEnum,
  hasAccessToAllStores: boolean,
  storeIds: number[],
  callback?: () => void
) {
  return async (dispatch: ThunkDispatch, getState: () => AppState) => {
    const state = getState();
    const appId = state.currentApp.AppId as string;

    try {
      dispatch(updateTeammateRequest());

      const result = await teammatesService.updateTeammate(
        appId,
        teammateId,
        appAccessLevel,
        hasAccessToAllStores,
        storeIds
      );
      dispatch(updateTeammateSuccess(result));
      if (callback) {
        callback();
      }
      dispatch(
        notify({
          message: 'Modified_successfully',
          translate: true,
          variant: 'success',
          disableWindowBlurListener: true,
        })
      );
    } catch (error) {
      dispatch(updateTeammateFailure(error, appId));
      dispatch(notifyError({ message: error.message, translate: true }));
    }
  };
}
// #endregion

// #region getTeammateDetails
export const TEAMMATE_DETAILS_LOAD = 'TEAMMATE_DETAILS_LOAD';
export const TEAMMATE_DETAILS_LOAD_REQUEST = 'TEAMMATE_DETAILS_LOAD_REQUEST';
export const TEAMMATE_DETAILS_LOAD_SUCCESS = 'TEAMMATE_DETAILS_LOAD_SUCCESS';
export const TEAMMATE_DETAILS_LOAD_FAILURE = 'TEAMMATE_DETAILS_LOAD_FAILURE';

export const getTeammateDetailsRequest = () => action(TEAMMATE_DETAILS_LOAD_REQUEST);

export type GetTeammateDetailsSuccess = ReturnType<typeof getTeammateDetailsSuccess>;
export const getTeammateDetailsSuccess = (props: Teammate) =>
  action(TEAMMATE_DETAILS_LOAD_SUCCESS, props);

export const getTeammateDetailsFailure = (error: Error, props) =>
  actionError(TEAMMATE_DETAILS_LOAD_FAILURE, props, error);

export function getTeammateDetails(teammateId: string) {
  return async (dispatch: ThunkDispatch, getState: () => AppState) => {
    const state = getState();
    const appId = state.currentApp.AppId as string;
    try {
      dispatch(action(SET_TEAMMATE_LOADING, true));
      dispatch(getTeammateDetailsRequest());

      const result = await teammatesService.getTeammate(appId, btoa(`${appId}|${teammateId}`));
      dispatch(getTeammateDetailsSuccess(result));
    } catch (error) {
      dispatch(getTeammateDetailsFailure(error, appId));
    }
    dispatch(action(SET_TEAMMATE_LOADING, false));
  };
}
// #endregion
