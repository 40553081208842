import { TeammatesApiFactory } from '@flipdish/api-client-typescript';

import { createApi } from '../../helpers/utilities';
import { mapServerError } from '../../services/utils/serverErrorMapper';

const teammatesApi = createApi(TeammatesApiFactory);

export const acceptInvitation = async (otc: string, appId: string) => {
  try {
    return await teammatesApi.teammatesAcceptInvitation(otc, appId);
  } catch (e) {
    const err = await mapServerError(e);
    throw err;
  }
};

export const redeemInvitation = async (otc: string, appId: string) => {
  try {
    return await teammatesApi.redeemInvitation(otc, appId);
  } catch (e) {
    const err = await mapServerError(e);
    throw err;
  }
};
