import React, { useEffect } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';

import LazyComponent from '@fd/ui/LazyComponent';

import RMSPage from '../components/RMSPage';
import { getStorefrontsKey } from '../rms.services';
import { RMSModules } from '../utils/rmsModules';
import { RMSSubscriptions } from '../utils/rmsSubscriptions';

type AddSalesChannelProps = { baseUrl: string } & MappedState;
const AddSalesChannel = (props: AddSalesChannelProps) => {
  const { translate, baseUrl } = props;
  const location = useLocation();
  const params = useParams<{ appId: string; type: string }>();

  const queryClient = useQueryClient();
  useEffect(() => {
    queryClient.invalidateQueries({ queryKey: [getStorefrontsKey] });
  }, []);

  const getBackUrl = () => {
    if (location.pathname.includes('website')) {
      return `/${params.appId}/sales-channels/website?tab=sales-channels`;
    }
    return `/${params.appId}/sales-channels/point-of-sale?tab=sales-channels`;
  };
  return (
    <LazyComponent>
      <RMSPage
        propertyIdFromUrl
        toParent={getBackUrl()}
        subscription={RMSSubscriptions.BASE}
        title={translate('Add_New_Sales_Channel')}
        module={RMSModules.ADD_NEW_STOREFRONT}
        url={`${baseUrl}/${RMSModules.ADD_NEW_STOREFRONT}`}
      />
    </LazyComponent>
  );
};

type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state) => ({
  translate: getTranslate(state.locale),
});

export default connect(mapStateToProps)(AddSalesChannel);
