import { SET_CURRENT_ORG, SET_SELECTED_PROPERTY } from './rms.actions';

const initialState = {
  selectedProperty: null,
  currentOrg: null,
};

const propertyReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SELECTED_PROPERTY:
      return {
        ...state,
        selectedProperty: action.payload,
      };
    case SET_CURRENT_ORG:
      return {
        ...state,
        currentOrg: action.payload,
      };
    default:
      return state;
  }
};

export default propertyReducer;
