import React, { useEffect } from 'react';

import { useMutationUpdateSalesChannelConfigHook } from '@fd/customHooks/useMutatationUpdateSalesChannelConfigHook';
import { useQuerySalesChannelConfigHook } from '@fd/customHooks/useQuerySalesChannelConfigHook';
import { getTranslate, Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import Permissions from 'react-redux-permissions';

import { Box } from '@fd/ui/atoms';
import PageLayout from '@fd/ui/Layout';

import { notifyError, NotifyProps, notifySaved } from '../../../layouts/Notify/actions';
import { permissionsSelector } from '../../../selectors/permissions.selector';
import { useTracking } from '../../../services/amplitude/useTracking';
import { CustomerAddressFormatSelectFilter } from '../components/CustomerAddressFormat';
import { RequestCustomersEmailSelectFilter } from '../components/RequestCustomersEmail';
import { ShowRestaurantListForDelivery } from '../components/ShowRestaurantListForDelivery';
import { WebToAppRedirectSelectFilter } from '../components/WebToAppRedirectSelectFilter';

export const SalesChannelsGeneralSettings = ({
  appId,
  hasEditSalesChannelsPermission,
  notifySaveError,
  notifySaveSuccess,
  translate,
}: MappedState & MappedDispatch) => {
  const { data, isLoading } = useQuerySalesChannelConfigHook(appId, true);
  const { trackEvent } = useTracking();
  useEffect(() => {
    trackEvent('portal_SalesChannels_generalSettings', {
      action: 'logged_in',
    });
  }, []);

  const handleConfigUpdateSuccess = () => {
    notifySaveSuccess();
  };

  const handleConfigUpdateFailed = (error) => {
    if (error) {
      notifySaveError(error);
    } else {
      notifySaveError({ message: 'Error_please_try_again_later', translate: true });
    }
  };

  const { mutate } = useMutationUpdateSalesChannelConfigHook(
    appId,
    handleConfigUpdateSuccess,
    handleConfigUpdateFailed
  );

  return (
    <PageLayout
      documentTitle="Settings"
      title={<Translate id="Settings" />}
      strictToParent
      toParent={`/${appId}/settings/sales-channels`}
    >
      <Permissions allowed={['ViewSalesChannels', 'EditSalesChannels']}>
        <Box>
          <RequestCustomersEmailSelectFilter
            isDisabled={!hasEditSalesChannelsPermission}
            isLoading={isLoading}
            onChange={mutate}
            translate={translate}
            value={data?.Data.EmailRequestMode}
          />
          <CustomerAddressFormatSelectFilter
            isDisabled={!hasEditSalesChannelsPermission}
            isLoading={isLoading}
            onChange={mutate}
            translate={translate}
            value={data?.Data.AddressEntryType}
          />
          <WebToAppRedirectSelectFilter
            isDisabled={!hasEditSalesChannelsPermission}
            isLoading={isLoading}
            onChange={mutate}
            translate={translate}
            value={data?.Data.WebToAppRedirect}
          />
          <ShowRestaurantListForDelivery
            isDisabled={!hasEditSalesChannelsPermission}
            isLoading={isLoading}
            onChange={mutate}
            translate={translate}
            value={data?.Data.DisplayDeliveryRestaurantListScreen}
          />
        </Box>
      </Permissions>
    </PageLayout>
  );
};

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
  notifySaveSuccess: () => dispatch(notifySaved()),
  notifySaveError: (data: NotifyProps) => dispatch(notifyError(data)),
});

type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => {
  const { currentApp } = state;
  const getPermissionsSelector = permissionsSelector.hasPermissionFactory(['EditSalesChannels']);
  return {
    appId: currentApp.AppId as string,
    hasEditSalesChannelsPermission: getPermissionsSelector(state),
    translate: getTranslate(state.locale),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SalesChannelsGeneralSettings);
