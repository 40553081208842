import React from 'react';

import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import { useParams } from 'react-router-dom';

import PageLayout from '@fd/ui/Layout';

import { RMSModules } from '../utils/rmsModules';
import { RMSSubscriptions } from '../utils/rmsSubscriptions';
import PropertyFilter from './PropertyFilter';
import RMSIframe from './RMSIframe';

const StyledGridItem = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(1.5),
  paddingLeft: 0,
  paddingBottom: theme.spacing(3),
  [theme.breakpoints.down('md')]: { padding: theme.spacing(1) },
}));

type RMSIframeProps = {
  module: RMSModules;
  url: string;
  subscription: RMSSubscriptions;
  title?: string | JSX.Element;
  includePropertyIdInUrl?: boolean;
  allowAllProperties?: boolean;
  allowFranchisorLevel?: boolean;
  pageLayoutEnabled?: boolean;
  toParent?: string;
  propertyIdFromUrl?: boolean;
  salesChannelIdFromUrl?: boolean;
};

const RMSPage = ({
  url,
  module,
  title,
  includePropertyIdInUrl,
  allowAllProperties,
  allowFranchisorLevel,
  subscription,
  pageLayoutEnabled = true,
  toParent = './',
  propertyIdFromUrl = false,
  salesChannelIdFromUrl = false,
}: RMSIframeProps) => {
  const params = useParams<{ channelId?: string; propertyId?: string }>();
  const channelId = params?.channelId;
  const propertyId = params?.propertyId;
  const content = (
    <>
      {includePropertyIdInUrl && (
        <Grid container>
          <StyledGridItem item xs={12} md={6}>
            <PropertyFilter
              subscription={subscription}
              includeAllPropertiesOption={allowAllProperties}
              allowEmptyPropertiesInUrl={allowFranchisorLevel || allowAllProperties}
            />
          </StyledGridItem>
        </Grid>
      )}
      <RMSIframe
        module={module}
        url={url}
        includePropertyIdInUrl={includePropertyIdInUrl}
        allowEmptyPropertiesInUrl={allowAllProperties || allowFranchisorLevel}
        storefrontId={salesChannelIdFromUrl ? channelId : undefined}
        propertyIdfromUrl={propertyIdFromUrl ? propertyId : undefined}
      />
    </>
  );

  return pageLayoutEnabled ? (
    <PageLayout title={title} strictToParent toParent={toParent}>
      {content}
    </PageLayout>
  ) : (
    content
  );
};

export default RMSPage;
