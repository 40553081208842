import React, { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';

import * as FlipdishApi from '@flipdish/api-client-typescript';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Radio from '@mui/material/Radio';
import { type Theme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import debounce from 'lodash/debounce';
import { getActiveLanguage, getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';

import { accountActions } from '../../actions/account.actions';
import { languageActions } from '../../actions/language.actions';
import { accountConstants } from '../../constants/account.constants';
import { usePrevious } from '../../custom-hooks/usePrevious';
import useRequestSnackBar from '../../custom-hooks/useRequestSnackBar';
import { languageCodeToLanguage } from '../../helpers/language';
import { timeZoneDefinitions } from '../../helpers/timeZones';
import { createLoadingSelector } from '../../selectors/loading.selector';
import { languagesSelector } from '../../selectors/locale.selector';
import PageLayout from '../../ui/Layout';
import GridContainer from '../../ui/Layout/GridContainer';
import PaperContainer from '../../ui/Layout/PaperContainer';
import Spacer from '../../ui/Spacer';
import ProfileCookie from './ProfileCookie';
import ProfileGravatar from './ProfileGravatar';

const updateLoadingSelector = createLoadingSelector([accountConstants.UPDATE]);

const useStyles = makeStyles(({ breakpoints, spacing }: Theme) => ({
  profile: {
    marginLeft: '48px',
    marginTop: '52px',
    [breakpoints.down('md')]: {
      display: 'none',
    },
  },
  profilesm: {
    display: 'none',
    [breakpoints.down('md')]: {
      marginLeft: '16px',
      marginTop: '32px',
      marginBottom: '32px',
      display: 'block',
    },
  },
  email: {
    marginTop: '21px',
    marginBottom: '8px',
    [breakpoints.down('md')]: {
      marginTop: '10px',
    },
    [breakpoints.only('xs')]: {
      textAlign: 'center',
    },
  },
  textfield: {
    marginTop: '16px',
    marginLeft: '0',
    marginRight: '0',
    maxWidth: '100%',
    [breakpoints.down('md')]: {
      marginTop: '20px',
    },
  },
  radio: {
    margin: '8px 0px 0px 0px',
    padding: '0px',
    height: '24px',
  },
  radioButton: {
    height: '24px',
    width: '24px',
    marginRight: '12px',
    '& svg > :first-child': {
      color: 'rgba(0, 0, 0, 0.54)',
    },
    '& span > :last-child': {
      color: 'rgba(0, 0, 0, 0.54)',
    },
  },
  text: {
    marginTop: '20px',
    height: '16px',
    lineHeight: '1.33',
    letterSpacing: '0.4px',
    opacity: 0.6,
    [breakpoints.down('md')]: {
      marginTop: '23px',
      marginBottom: '4px',
    },
  },
  gridItem: {
    padding: spacing(1.5),
    [breakpoints.down('md')]: { padding: spacing(1) },
  },
}));

export type Props = MappedState & { dispatch: ThunkDispatch; hideBackButton?: boolean };

const Profile = ({
  account,
  activeLanguage,
  appId,
  autoSaving,
  dispatch,
  languages,
  translate,
  updatedFailure,
  updatedSuccess,
  hideBackButton = false,
}: Props) => {
  const classes = useStyles();
  const previousLanguage = usePrevious(account.Language);
  const [Name, setName] = useState(account.Name);
  const [TimeZoneInfoId, setTimeZoneInfoId] = useState(
    account.TimeZoneInfoId || 'GMT Standard Time'
  );
  const [displayTimeInUserTimeZone, setDisplayTimeInUserTimeZone] = useState(
    account.DisplayTimesInUserLocalTimeZone != undefined
      ? account.DisplayTimesInUserLocalTimeZone
      : true
  );
  const [Language, setLanguage] = useState(account.Language);
  const [accountNameErrorField, setAccountNameErrorField] = useState(false);

  useRequestSnackBar({
    autoSaving,
    success: updatedSuccess,
    successMessage: 'Your_profile_has_been_updated',
    failure: updatedFailure,
    failureMessage: account.error ? account.error.message : '',
  });

  const debounceChange = useRef<any>(null);

  const onChange = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
      const { name, value } = event.target;

      const accountUpdate = account;
      accountUpdate[name] = value;

      if (value.length > 256) {
        return setAccountNameErrorField(true);
      } else {
        setAccountNameErrorField(false);
      }

      switch (name) {
        case 'Name':
          !accountNameErrorField && setName(value);
          break;
        case 'Language':
          setLanguage(value);
          break;
        case 'TimeZoneInfoId':
          setTimeZoneInfoId(value);
          break;
        default:
          break;
      }

      debouncedUpdate(accountUpdate);
    },
    []
  );

  const debouncedUpdate = useCallback((account: Partial<FlipdishApi.AccountDetailBase>) => {
    if (debounceChange.current) {
      debounceChange.current.cancel();
    }
    debounceChange.current = debounce(() => {
      dispatch(accountActions.update(account));
    }, 900);
    debounceChange.current();
  }, []);

  const handleRadioChange = useCallback(
    (userTimeZone: boolean) => () => {
      setDisplayTimeInUserTimeZone(userTimeZone);

      const accountUpdate = { ...account };
      accountUpdate.DisplayTimesInUserLocalTimeZone = userTimeZone;
      debouncedUpdate(accountUpdate);
    },
    []
  );

  const timeZones = timeZoneDefinitions;

  useEffect(() => {
    if (updatedSuccess && Language && previousLanguage !== Language) {
      dispatch(languageActions.setLanguage(Language));
    }
  }, [updatedSuccess, previousLanguage, Language]);

  return (
    <PageLayout
      toParent={hideBackButton ? '' : `/${appId}/home`}
      documentTitle="My_account"
      title={translate('My_account')}
    >
      <PaperContainer>
        <GridContainer>
          <Grid item xs={12} sm={3} className={classes.gridItem}>
            <Grid container justifyContent="center">
              <Grid item xs={6} sm={12}>
                <ProfileGravatar />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={9} lg={6} className={classes.gridItem}>
            <Grid container direction="column">
              <Typography variant="h5" className={classes.email} data-fd="email">
                {account.Email}
              </Typography>
              <TextField
                variant="standard"
                multiline
                label={translate('Your_name')}
                className={classes.textfield}
                name={'Name'}
                value={Name}
                onChange={onChange}
                inputProps={{
                  'data-fd': 'txt-name',
                }}
                error={accountNameErrorField}
                helperText={accountNameErrorField && translate('Error_account_name_max256Char')}
              />
              <TextField
                variant="standard"
                label={translate('Your_timezone')}
                value={TimeZoneInfoId}
                select
                name="TimeZoneInfoId"
                className={classes.textfield}
                onChange={onChange}
                inputProps={
                  {
                    // 'data-fd': 'lst-timezone'
                  }
                }
                SelectProps={{
                  SelectDisplayProps: {
                    // @ts-ignore
                    'data-fd': 'lst-timezone',
                  },
                }}
              >
                {timeZones.map((timeZone) => (
                  <MenuItem key={timeZone.TimeZoneId} value={timeZone.TimeZoneId}>
                    {timeZone.DisplayName}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                variant="standard"
                label={translate('Your_language')}
                value={Language}
                select
                className={classes.textfield}
                name={'Language'}
                onChange={onChange}
                SelectProps={{
                  SelectDisplayProps: {
                    // @ts-ignore
                    'data-fd': 'lst-language',
                  },
                }}
              >
                {languages
                  .filter((x) => x !== 'dev-lang')
                  .map((x) => (
                    <MenuItem
                      key={x}
                      value={x}
                      disabled={activeLanguage === x}
                      data-fd={`language-${x}`}
                    >
                      {languageCodeToLanguage(x)}
                    </MenuItem>
                  ))}
              </TextField>
              <Typography variant="caption" className={classes.text}>
                {translate('Display_times_in')}
              </Typography>
              <FormControlLabel
                className={classes.radio}
                control={
                  <Radio
                    className={classes.radioButton}
                    checked={displayTimeInUserTimeZone}
                    data-fd="rdo-your-timezone"
                    onClick={handleRadioChange(true)}
                  />
                }
                label={translate('Your_timezone')}
              />
              <FormControlLabel
                className={classes.radio}
                control={
                  <Radio
                    className={classes.radioButton}
                    checked={!displayTimeInUserTimeZone}
                    data-fd="rdo-your-stores-timezone"
                    onClick={handleRadioChange(false)}
                  />
                }
                label={translate('Your_stores_timezone')}
              />
              <ProfileCookie />
              <Spacer size={16} />
            </Grid>
          </Grid>
        </GridContainer>
      </PaperContainer>
    </PageLayout>
  );
};

type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => {
  const { account, locale } = state;
  return {
    account,
    translate: getTranslate(locale),
    appId: state.currentApp.AppId,
    languages: languagesSelector(state),
    activeLanguage: getActiveLanguage(state.locale),
    autoSaving: updateLoadingSelector(state),
    updatedSuccess: state.account.updateSuccess,
    updatedFailure: state.account.updateFailure,
  };
};

export default connect(mapStateToProps)(Profile);
