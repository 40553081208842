import React, { useEffect } from 'react';

import { TermsAndPolicyData } from '@flipdish/api-client-typescript/private/api';
import { Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import PageLayout from '../../../ui/Layout';
import PaperContainer from '../../../ui/Layout/PaperContainer';
import EditTermsAndPolicyDataForm from '../components/EditTermsAndPolicyDataForm';
import { getTermsAndPolicyData, getTermsAndPolicyDataRequestSuccess } from '../settings.actions';
import { getTermsAndPolicySelector } from '../settings.selectors';

type InnerProps = MappedProps & MappedDispatch;
type OuterProps = {};
type Props = InnerProps & OuterProps;

const EditTermsAndPolicyData = (props: Props) => {
  const { appId, getTermsAndPolicyData, onSubmitSuccess, termsAndPolicyData } = props;
  useEffect(() => {
    getTermsAndPolicyData();
  }, []);

  return (
    <PageLayout
      documentTitle="Legal"
      title={<Translate id="Legal" />}
      caption={<Translate id="Terms_and_Policy_documents" />}
      strictToParent
      toParent={`/${appId}/settings/sales-channels`}
    >
      <PaperContainer>
        <EditTermsAndPolicyDataForm
          appId={appId!}
          termsAndPolicyData={termsAndPolicyData}
          onSubmitSuccess={onSubmitSuccess}
        />
      </PaperContainer>
    </PageLayout>
  );
};

type MappedProps = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => {
  return {
    appId: state.currentApp.AppId,
    termsAndPolicyData: getTermsAndPolicySelector(state),
  };
};

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
  getTermsAndPolicyData: () => dispatch(getTermsAndPolicyData()),
  onSubmitSuccess: (appId: string, data: TermsAndPolicyData) =>
    dispatch(getTermsAndPolicyDataRequestSuccess(appId, data)),
});

export default compose<Props, {}>(connect(mapStateToProps, mapDispatchToProps))(
  EditTermsAndPolicyData
);
