import Auth0Login from '../components/Auth/Auth0/Auth0Login';
import Auth0LoginCallback from '../components/Auth/Auth0/Auth0LoginCallback';
import CreatePassword from '../components/Auth/CreatePassword';
import Logout from '../components/Auth/Logout';
import OnboardingComponentWrapper from '../components/Onboarding/components/OnboardingComponentWrapper';
import OnboardingSetup from '../components/Onboarding/OnboardingSetup';
import OnboardingRoutes from '../components/OnboardingV2/Onboarding.routes';
import SelfServeRoutes from '../components/SelfServe/SelfServe.routes';
import AcceptInvitation from '../components/Teammates/Invitation/AcceptInvitation';
import { Auth0CallbackPath } from '../constants/auth0.constants';
import { signup } from '../constants/signup.constants';
import { AuthGate } from '../layouts/Auth/AuthGate';

export const accountRoutesConst = {
  Root: '/',
  Login: '/login',
  Logout: '/logout',
  Signup: '/signup',
  SignupFlow: '/signup/steps',
  SubscriptionsSelfServeMicroFrontend: '/self-serve',
  CreatePassword: '/create_password',
  ForgotPassword: '/forgot_password',
  AcceptInvitation: '/accept_invitation',
  Auth0Login: '/auth0login',
  Auth0LoginCallback: Auth0CallbackPath,
};

export const signupV2Const = {
  BusinessOwnerInfo: '/signup/business-owner-info',
  RestaurantDetails: '/signup/restaurant-details',
  BrandDetails: '/signup/brand-details',
};

export const onboardingRoutesConst = {
  Welcome: '/welcome_screen',
  MyAccount: '/my_account',
  ProfileAccount: '/profile_account',
  BankingDetails: '/:appId/finance/bank-accounts/:accountId',
  Onboarding: '/onboarding',
};

export const firstTimeSetupRoutesV2: (RoutePropsExtended | RedirectPropsExtended)[] = [
  {
    name: 'signup_v2.business_owner_info',
    group: 'first_time_setup_V2',
    path: signupV2Const.BusinessOwnerInfo,
    component: OnboardingRoutes,
    exact: true,
  },
  {
    name: 'signup_v2.restaurant_details',
    group: 'first_time_setup_V2',
    path: signupV2Const.RestaurantDetails,
    component: OnboardingRoutes,
    exact: true,
  },
  {
    name: 'signup_v2.brand_details',
    group: 'first_time_setup_V2',
    path: signupV2Const.BrandDetails,
    component: OnboardingRoutes,
    exact: true,
  },
  {
    name: 'signup.redirect_index',
    group: 'first_time_setup_V2',
    path: '*',
    to: signupV2Const.BusinessOwnerInfo,
  },
];

export const firstTimeSetupRoutes: (RoutePropsExtended | RedirectPropsExtended)[] = [
  {
    name: 'signup',
    group: 'first_time_setup',
    path: accountRoutesConst.SignupFlow,
    component: AuthGate,
    exact: true,
  },
  {
    name: 'signup.storename',
    group: 'first_time_setup',
    path: signup.paths.storename,
    component: AuthGate,
    exact: true,
  },
  {
    name: 'signup.location',
    group: 'first_time_setup',
    path: signup.paths.location,
    component: AuthGate,
    exact: true,
  },
  {
    name: 'signup.confirm_location',
    group: 'first_time_setup',
    path: signup.paths.locationConfirm,
    component: AuthGate,
    exact: true,
  },
  {
    name: 'signup.survey',
    group: 'first_time_setup',
    path: signup.paths.survey,
    component: AuthGate,
    exact: true,
  },
  {
    name: 'signup.redirect_index',
    group: 'first_time_setup',
    path: '*',
    to: signup.paths.location,
  },
];

export const onboardingRoute: (RoutePropsExtended | RedirectPropsExtended)[] = [
  {
    name: 'banking_details',
    group: 'onboarding_setup',
    path: onboardingRoutesConst.BankingDetails,
    component: OnboardingComponentWrapper,
    exact: true,
  },
  {
    name: 'welcome_screen',
    group: 'onboarding_setup',
    path: '*',
    component: OnboardingSetup,
  },
];

export const publicRoutes: (RoutePropsExtended | RedirectPropsExtended)[] = [
  {
    name: 'accept_invitation',
    group: 'publicAndPrivate',
    path: accountRoutesConst.AcceptInvitation,
    component: AcceptInvitation,
    exact: true,
  },
  {
    name: 'auth.login',
    group: 'public',
    path: accountRoutesConst.Login,
    component: Auth0Login,
    exact: true,
  },
  {
    name: 'auth.auth0login',
    group: 'public',
    path: accountRoutesConst.Auth0Login,
    component: Auth0Login,
    exact: true,
  },
  {
    name: 'auth.auth0logincallback',
    group: 'public',
    path: accountRoutesConst.Auth0LoginCallback,
    component: Auth0LoginCallback,
    exact: true,
  },
  {
    name: 'auth.signup',
    group: 'public',
    path: accountRoutesConst.Signup,
    component: Auth0Login,
    exact: true,
  },
  {
    name: 'auth.create_password',
    group: 'publicAndPrivate',
    path: accountRoutesConst.CreatePassword,
    component: CreatePassword,
    exact: true,
  },
  {
    name: 'auth.forgot_password',
    group: 'public',
    path: accountRoutesConst.ForgotPassword,
    component: Auth0Login,
    exact: true,
  },
  {
    name: 'subscriptions.self_serve',
    group: 'public',
    path: accountRoutesConst.SubscriptionsSelfServeMicroFrontend,
    component: SelfServeRoutes,
    exact: false,
  },
  {
    name: 'auth.redirect_index',
    group: 'public',
    path: '*',
    to: accountRoutesConst.Login,
  },
  {
    name: 'auth.logout',
    group: 'publicAndPrivate',
    path: accountRoutesConst.Logout,
    component: Logout,
    exact: true,
  },
];
