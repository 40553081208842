import React from 'react';

import { Translate } from 'react-localize-redux';
import Permissions from 'react-redux-permissions';
import { Route, RouteComponentProps, Switch, withRouter } from 'react-router';

import { store } from '../../helpers/store';
import { toKebabCase } from '../../helpers/strings';
import ErrorBoundary from '../../layouts/Portal/ErrorBoundary';
import { flagService } from '../../services/flagService/flagService';
import RMSPage from '../RMS/components/RMSPage';
import SalesChannelRoutes from '../RMS/SalesChannels/SalesChannelRoutes';
import { RMSModules } from '../RMS/utils/rmsModules';
import { RMSSubscriptions } from '../RMS/utils/rmsSubscriptions';
import WebsiteDomainSetup from '../WebsiteAndApp/components/SetupDomain';
import CookieSettings from '../WebsiteAndApp/CookieSettings/CookieSettings';
import WebsiteSettings from '../WebsiteAndApp/WebSiteSettings/WebsiteSettings';
import AddChannel from './AddChannel/AddChannel';
import ChannelSettings from './ChannelSettings/ChannelSettings';
import { MobileApps } from './MobileApps/pages/MobileApps';
import POSPage from './POS/POSPage';
import StoreMapping from './StoreMapping/StoreMapping';
import StoreVisibilityPage from './Website/StoreVisibilityPage';
import SalesChannels from './Website/WebsiteSettings';

type RouteParams = {
  appId?: string;
};

type Props = RouteComponentProps<RouteParams>;
const SalesChannelsRoutes: React.FC<React.PropsWithChildren<Props>> = (props: Props) => {
  const {
    match: { path, params },
  } = props;

  const addChannelFlag = flagService.isFlagOn(store.getState(), 'addChannel');

  return (
    <ErrorBoundary identifier="sales-channels">
      <Switch>
        <>
          <Permissions allowed={['ViewSalesChannels', 'EditSalesChannels']}>
            <Route exact path={`${path}/mobile-apps`} component={MobileApps} />

            <Route path={`${path}/website-settings`} component={SalesChannels} />

            <Route
              exact
              path={`${path}/website/analytics-and-social`}
              component={WebsiteSettings}
            />
            <Route exact path={`${path}/website/domain-setup`} component={WebsiteDomainSetup} />
            <Route exact path={`${path}/website/cookie-management`} component={CookieSettings} />
            <Route
              exact
              path={`${path}/website/store-visibility`}
              component={StoreVisibilityPage}
            />

            {addChannelFlag && <Route exact path={`${path}/add-channel`} component={AddChannel} />}

            {addChannelFlag && (
              <Route exact path={`${path}/:channel`} component={ChannelSettings} />
            )}
            {addChannelFlag && (
              <Route exact path={`${path}/:channel/store-mapping`} component={StoreMapping} />
            )}
          </Permissions>
          <Permissions allowed={['Owner', 'ManagedOwner', 'FlipdishStaff']}>
            <Route exact path={`${path}/point-of-sale`} component={POSPage} />
            <Route
              exact
              path={`${path}/point-of-sale/network-diagram`}
              render={() => (
                <RMSPage
                  subscription={RMSSubscriptions.BASE}
                  title={<Translate id="Network_Diagram" />}
                  module={RMSModules.NETWORK_DIAGRAM}
                  url={`configurations/system/${RMSModules.NETWORK_DIAGRAM}`}
                  includePropertyIdInUrl
                  toParent={`/${params?.appId}/sales-channels/point-of-sale?tab=settings`}
                />
              )}
            />
            <Route path={`${path}/:type(point-of-sale|website)`} component={SalesChannelRoutes} />
            <Route
              exact
              path={`${path}/point-of-sale/${RMSModules.GUESTS}`}
              render={() => (
                <RMSPage
                  subscription={RMSSubscriptions.BASE}
                  title={<Translate id="Guests" />}
                  module={RMSModules.GUESTS}
                  url={`configurations/system/${RMSModules.GUESTS}`}
                  toParent={`/${params?.appId}/sales-channels/point-of-sale?tab=settings`}
                />
              )}
            />
            <Route
              exact
              path={`${path}/point-of-sale/${toKebabCase(RMSModules.PRINT_CONFIGURATION)}`}
              render={() => (
                <RMSPage
                  subscription={RMSSubscriptions.BASE}
                  title={<Translate id="Printer" />}
                  module={RMSModules.PRINT_CONFIGURATION}
                  url={`configurations/franchisors/properties/${RMSModules.PRINT_CONFIGURATION}`}
                  includePropertyIdInUrl
                  toParent={`/${params?.appId}/sales-channels/point-of-sale?tab=settings`}
                />
              )}
            />
            <Route
              exact
              path={`${path}/point-of-sale/${toKebabCase(RMSModules.FLOAT_CONFIGURATION)}`}
              render={() => (
                <RMSPage
                  subscription={RMSSubscriptions.BASE}
                  title={<Translate id="Float_Configuration" />}
                  module={RMSModules.FLOAT_CONFIGURATION}
                  url={`configurations/franchisors/properties/${RMSModules.FLOAT_CONFIGURATION}`}
                  includePropertyIdInUrl
                  toParent={`/${params?.appId}/sales-channels/point-of-sale?tab=settings`}
                />
              )}
            />
            <Route
              exact
              path={`${path}/point-of-sale/${RMSModules.TAGS}`}
              render={() => (
                <RMSPage
                  subscription={RMSSubscriptions.BASE}
                  title={<Translate id="Tags" />}
                  module={RMSModules.TAGS}
                  url={`configurations/system/${RMSModules.TAGS}`}
                  toParent={`/${params?.appId}/sales-channels/point-of-sale?tab=settings`}
                />
              )}
            />
            <Route
              exact
              path={`${path}/point-of-sale/${toKebabCase(RMSModules.PRICE_BANDS)}`}
              render={() => (
                <RMSPage
                  subscription={RMSSubscriptions.BASE}
                  title={<Translate id="Price_Bands" />}
                  module={RMSModules.PRICE_BANDS}
                  url={`configurations/system/${RMSModules.PRICE_BANDS}`}
                  toParent={`/${params?.appId}/sales-channels/point-of-sale?tab=settings`}
                />
              )}
            />
            <Route
              exact
              path={`${path}/point-of-sale/payment-methods`}
              render={() => (
                <RMSPage
                  subscription={RMSSubscriptions.BASE}
                  title={<Translate id="Payment_Types" />}
                  module={RMSModules.PAYMENT_TYPES}
                  url={`configurations/system/${RMSModules.PAYMENT_TYPES}`}
                  toParent={`/${params?.appId}/sales-channels/point-of-sale?tab=settings`}
                />
              )}
            />
            <Route
              exact
              path={`${path}/point-of-sale/${toKebabCase(RMSModules.FREEBIE_REASONS)}`}
              render={() => (
                <RMSPage
                  subscription={RMSSubscriptions.BASE}
                  title={<Translate id="Freebie_Reasons" />}
                  module={RMSModules.FREEBIE_REASONS}
                  url={`configurations/system/${RMSModules.FREEBIE_REASONS}`}
                  toParent={`/${params?.appId}/sales-channels/point-of-sale?tab=settings`}
                />
              )}
            />
            <Route
              exact
              path={`${path}/point-of-sale/${toKebabCase(RMSModules.ADDRESS_MAPPING)}`}
              render={() => (
                <RMSPage
                  subscription={RMSSubscriptions.BASE}
                  title={<Translate id="Address_Mapping" />}
                  module={RMSModules.ADDRESS_MAPPING}
                  url={`configurations/system/${RMSModules.ADDRESS_MAPPING}`}
                  toParent={`/${params?.appId}/sales-channels/point-of-sale?tab=settings`}
                  includePropertyIdInUrl
                />
              )}
            />
            <Route
              exact
              path={`${path}/point-of-sale/layout`}
              render={() => (
                <RMSPage
                  subscription={RMSSubscriptions.BASE}
                  title={<Translate id="Layout_Configuration" />}
                  module={RMSModules.LAYOUT_CONFIGURATION}
                  url={`configurations/franchisors/properties/${RMSModules.LAYOUT_CONFIGURATION}`}
                  includePropertyIdInUrl
                  toParent={`/${params?.appId}/sales-channels/point-of-sale?tab=settings`}
                />
              )}
            />
          </Permissions>
        </>
      </Switch>
    </ErrorBoundary>
  );
};

export default withRouter(SalesChannelsRoutes);
