import React from 'react';

import PrintOutlinedIcon from '@mui/icons-material/PrintOutlined';
import List from '@mui/material/List';
import { connect } from 'react-redux';

import ListItemLinkButton from '@fd/ui/List/ListItemLinkButton';

import generalSettingsIcon from '../../../assets/images/General_settings.svg';
import orderSettingsIcon from '../../../assets/images/ico_order_settings.svg';
import legalIcon from '../../../assets/images/Legal.svg';
import { toKebabCase } from '../../../helpers/strings';
import { getTranslate } from '../../../overrides/react-localize-redux';
import { flagService } from '../../../services/flagService';
import PageLayout from '../../../ui/Layout';
import PaperContainer from '../../../ui/Layout/PaperContainer';

const SalesChannelsList = (props: MappedState) => {
  const { AppId, translate, isOrderStatusConfigPageEnabled } = props;

  const baseUrl = `/${AppId}/settings/sales-channels`;

  return (
    <PageLayout
      documentTitle="Sales_channels"
      title={`${translate('Settings')} - ${translate('Sales_channels')}`}
    >
      <PaperContainer fluid>
        <List aria-label={translate('Sales_channels') as string}>
          <ListItemLinkButton
            title="General_settings"
            subtitle="Customer_email_use_address_entry_website_redirect"
            link={`${baseUrl}/${toKebabCase('general-settings')}`}
            iconComponent={<img src={generalSettingsIcon} />}
          />
          {isOrderStatusConfigPageEnabled && (
            <ListItemLinkButton
              title="Order_status_configuration"
              subtitle="Configure_the_order_statuses_for_your_sales_channe"
              iconComponent={<img src={orderSettingsIcon} alt="Order status configuration" />}
              link={`/${AppId}/orderFulfillmentStatus`}
            />
          )}
          <ListItemLinkButton
            title="Legal"
            subtitle="Configure_the_terms_and_policy_documents_displayed"
            link={`${baseUrl}/${toKebabCase('legal')}`}
            iconComponent={<img src={legalIcon} />}
          />
          <ListItemLinkButton
            title="Printing_Templates"
            subtitle="Setup_the_templates_for_receipts_and_other_printed"
            link={`${baseUrl}/${toKebabCase('printing-templates')}`}
            iconComponent={<PrintOutlinedIcon />}
            isLast={true}
          />
        </List>
      </PaperContainer>
    </PageLayout>
  );
};

type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => {
  return {
    translate: getTranslate(state.locale),
    AppId: state.currentApp.AppId,
    isOrderStatusConfigPageEnabled: flagService.isFlagOn(
      state,
      'orderFulfillmentStatusConfigurations'
    ),
  };
};

export default connect(mapStateToProps)(SalesChannelsList);
