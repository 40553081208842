import React, { useCallback, useState } from 'react';

import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import { type Theme, useTheme } from '@mui/material/styles';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import useMediaQuery from '@mui/material/useMediaQuery';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';

import { ItemMetaData } from './menuItemsConfig';
import SideNavMenu from './SideNavMenu';
import SideNavSubDrawer from './SideNavSubDrawer';

const drawerWidth = 248;
const useStyles = makeStyles((theme: Theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    borderRight: 'none',
    height: '100%',
    overflow: 'hidden',
  },
  drawerOpen: {
    width: drawerWidth,
    borderRight: 'none',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    borderRight: 'none',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: 74,
  },
  mobileDrawer: {
    width: 280,
  },
  listWrapper: {
    width: '100%',
    height: '100%',
    overflowY: 'scroll',
    overflowX: 'hidden',
    paddingRight: '17px',
    boxSizing: 'content-box',
    '-webkit-overflow-scrolling': 'touch',
  },
  list: {
    width: drawerWidth,
    paddingRight: theme.spacing(2),
  },
  desktopPaper: {
    borderRight: '1px solid #DBDCDF',
    position: 'static',
    overflow: 'hidden',
  },
}));

export const SideNavContext = React.createContext({ toggleSideNav: () => {}, showTooltip: false });

type Props = {
  open: boolean;
  toggleSideNav: () => void;
  dynamicChannelSideMenuItems?: ItemMetaData[];
  openSubDrawer: boolean;
  setOpenSubDrawer: (isOpen: boolean) => void;
};

const SideNav = ({
  open,
  toggleSideNav,
  dynamicChannelSideMenuItems,
  openSubDrawer,
  setOpenSubDrawer,
}: Props) => {
  const classes = useStyles({ drawerWidth });
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('lg'));

  const onClose = useCallback(() => {
    if (open) {
      toggleSideNav();
    }
  }, [open, toggleSideNav]);

  const onOpen = useCallback(() => {
    if (!open) {
      toggleSideNav();
    }
  }, [open, toggleSideNav]);

  if (matches) {
    return (
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.desktopPaper]: true,
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
        open={open}
      >
        <nav className={classes.listWrapper} aria-label="Primary navigation">
          {openSubDrawer ? (
            <SideNavSubDrawer setOpenSideNav={setOpenSubDrawer} toggleSideNav={toggleSideNav} />
          ) : (
            <List className={classes.list}>
              <SideNavContext.Provider value={{ showTooltip: !open, toggleSideNav: toggleSideNav }}>
                <SideNavMenu
                  dynamicChannelSideMenuItems={dynamicChannelSideMenuItems}
                  setOpenSideNav={setOpenSubDrawer}
                />
              </SideNavContext.Provider>
            </List>
          )}
        </nav>
      </Drawer>
    );
  }

  return (
    <SwipeableDrawer
      classes={{ paper: classes.mobileDrawer }}
      open={open}
      onClose={onClose}
      onOpen={onOpen}
    >
      <List>
        <SideNavContext.Provider value={{ showTooltip: false, toggleSideNav: toggleSideNav }}>
          {openSubDrawer ? (
            <SideNavSubDrawer setOpenSideNav={setOpenSubDrawer} toggleSideNav={toggleSideNav} />
          ) : (
            <SideNavMenu
              dynamicChannelSideMenuItems={dynamicChannelSideMenuItems}
              setOpenSideNav={setOpenSubDrawer}
            />
          )}
        </SideNavContext.Provider>
      </List>
    </SwipeableDrawer>
  );
};

export default SideNav;
