import React from 'react';

import { Grid } from '@mui/material';
import { type Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { useMutation, useQuery } from '@tanstack/react-query';
import clsx from 'clsx';
import { Translate } from 'react-localize-redux';
import { connect } from 'react-redux';

import PageLayout from '@fd/ui/Layout';

import EmptyIcon from '../../assets/images/empty_store_list.svg';
import {
  closeNotifyLoading,
  notify,
  notifyError,
  notifyLoading,
  NotifyProps,
} from '../../layouts/Notify/actions';
import EmptyComponent from '../../ui/EmptyComponent';
import GridContainer, { useCardStyles } from '../../ui/Layout/GridContainer';
import PropertyCard from './components/PropertyCard';
import {
  createPropertyForOrg,
  getAllPropertiesForOrg,
  getAllPropertiesForOrgKey,
} from './propertiesService';

const useStyles = makeStyles((theme: Theme) => ({
  gridItem: {
    padding: theme.spacing(1.5),
    [theme.breakpoints.down('md')]: { padding: theme.spacing(1) },
  },
}));

type Props = MappedProps & MappedDispatch;

export const PropertiesList = (props: Props) => {
  const { closeNotifyUpdating, notifyPublishError, notifyUpdating } = props;

  const cardClasses = useCardStyles();
  const classes = useStyles();

  const orgId = 'org3704'; // Just a placeholder for now

  const { data, isPending, isFetching } = useQuery({
    queryKey: [getAllPropertiesForOrgKey, orgId],
    queryFn: () => getAllPropertiesForOrg(orgId),
    enabled: !!orgId,
  });

  const createProperty = useMutation({
    mutationFn: (orgId: string) => {
      notifyUpdating();
      return createPropertyForOrg(orgId);
    },
    onSuccess: () => {
      closeNotifyUpdating();
    },
    onError: () => {
      closeNotifyUpdating();
      notifyPublishError({ message: 'Error_please_try_again_later', translate: true });
    },
  });

  return (
    <PageLayout
      actionBtnTitle={'Add_Property'}
      documentTitle="Properties"
      showActionButton
      showAddIcon
      title={<Translate id="Properties" />}
      userPermissions={'Owner'}
      onClick={() => {
        console.log('clicked modal open');
      }} // Add Property // This will be a modal to add a new property
    >
      <GridContainer alignItems="stretch" cards key="list">
        {data &&
          data.map((p) => (
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              lg={3}
              key={p.name}
              className={clsx(cardClasses.card, classes.gridItem)}
            >
              <PropertyCard propertyData={p} />
            </Grid>
          ))}
      </GridContainer>

      {(!data || data.length === 0) && !isPending && !isFetching && (
        <EmptyComponent
          title="No_properties_found"
          subtitle="Add_properties_to_your_organization"
          icon={EmptyIcon}
          noLink
        />
      )}
    </PageLayout>
  );
};

type MappedProps = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => ({
  appId: state.currentApp.AppId,
});

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
  closeNotifyUpdating: () => dispatch(closeNotifyLoading()),
  showUpdateSuccessMessage: (data: NotifyProps) => dispatch(notify(data)),
  notifyPublishError: (data: NotifyProps) => dispatch(notifyError(data)),
  notifyUpdating: () => dispatch(notifyLoading({ persist: true })),
});

export default connect(mapStateToProps, mapDispatchToProps)(PropertiesList);
