import React, { useEffect, useState } from 'react';

import { Teammate } from '@flipdish/api-client-typescript';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { refreshJwtToken } from '../../../services/auth.service';
import Loading from '../../Loading';
import { acceptInvitation, redeemInvitation } from '../teammates.service';
import { setAcceptInviteFlowAsCompleted, setAcceptInviteFlowAsStarted } from '../teammateutils';

const useStyles = makeStyles(() => ({
  root: {
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#fff',
    flexDirection: 'column',
  },
  headline: {
    lineHeight: '1.2',
    marginBottom: '4px',
  },
  redirecting: {
    fontSize: '20px',
    color: 'rgba(0, 0, 0, 0.54)',
    lineHeight: '1.4',
    letterSpacing: 'normal',
  },
}));

type Props = MappedDispatch & MappedState;

const AcceptInvitation = (props: Props) => {
  const { account } = props;

  const classes = useStyles();
  const history = useHistory();
  const [expired, setExpired] = useState(false);
  const [loading, setLoading] = useState(true);
  const [initialized, setInitialized] = useState(false);
  const [error, setError] = useState(false);
  const { search } = useLocation();

  useEffect(() => {
    const urlParams = new URLSearchParams(search);
    const otc = urlParams.get('otc');

    if (!otc) {
      setInitialized(true);
      setError(true);
      console.error(new Error('Invitation token is required!'));
    } else {
      setAcceptInviteFlowAsStarted();

      if (!account?.authorized) {
        // User is not authenticated, redirect to sign on or signup
        acceptInvitation(otc, '-').then((response) => {
          const { IsNewUser, InvitedEmailAddress } = response.Data;
          const email = encodeURIComponent(InvitedEmailAddress ?? '');
          // After the user has authenticated, redirect them back to this page
          const redirectUrl = encodeURIComponent(window.location.href);

          if (IsNewUser) {
            // Invitation is to new user, send them to signup
            const signupUrl = `/login?screen_hint=signup&redirectUrl=${redirectUrl}&email=${email}`;
            history.replace(signupUrl);
          } else {
            // Invitation to existing user, send them to login
            const loginUrl = `/login?redirectUrl=${redirectUrl}&email=${email}`;
            history.replace(loginUrl);
          }
        });
      } else {
        // User is authorized, so redeem the invite.
        redeemInvitation(otc, '-').then((response) => {
          const { AppId, InvitationStatus } = response.Data;

          if (InvitationStatus === Teammate.InvitationStatusEnum.Accepted) {
            if (AppId) {
              localStorage.setItem('fd-currentAppId', AppId);
            }

            setAcceptInviteFlowAsCompleted();
            refreshJwtToken().then(() => {
              window.location.href = `/${AppId}/home`;
            });
          } else {
            // If the invite has not been accepted, there was some issue accepting the invite.
            // Log the user out to force them to reauthenticate
            setExpired(true);
            setError(true);
            console.error(new Error('Issue Redeeming Invitation'));
            window.location.href = `/logout`;
          }
        });
      }
    }
  }, []);

  if (loading) {
    return <Loading fullscreen animate={!initialized} onAnimationEnd={() => setLoading(false)} />;
  }

  if (expired) {
    return (
      <div className={classes.root}>
        <Typography variant="h2" color="primary" align="center" className={classes.headline}>
          <Translate id="Your_invitation_already_expired" />
        </Typography>
        <Typography color="textSecondary" align="center" className={classes.redirecting}>
          <Translate id="Redirecting_to" data={{ routeName: 'Login...' }} />
        </Typography>
      </div>
    );
  }

  if (error) {
    return (
      <div className={classes.root}>
        <Typography variant="h2" color="error" align="center">
          <Translate id="Error_please_try_again_later" />
        </Typography>
      </div>
    );
  }

  return <Loading fullscreen />;
};

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (dispatch: ThunkDispatch) => {
  return {};
};

type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => {
  const { account } = state;
  return {
    account,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AcceptInvitation);
