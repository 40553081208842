import React from 'react';

import { type RouteComponentProps, Route, Switch } from 'react-router';

import LazyComponent from '@fd/ui/LazyComponent';

import { toKebabCase } from '../../../helpers/strings';
import { lazyWithRetry } from '../../../helpers/utilities';
import ErrorBoundary from '../../../layouts/Portal/ErrorBoundary';
import { Translate } from '../../../overrides/react-localize-redux';
import { RMSModules } from '../utils/rmsModules';
import { RMSSubscriptions } from '../utils/rmsSubscriptions';

const Sales = lazyWithRetry(() => import('./Sales'));
const RMSPage = lazyWithRetry(() => import('../components/RMSPage'));

const SalesRoutes: React.FC<React.PropsWithChildren<RouteComponentProps>> = (
  props: RouteComponentProps
) => {
  const {
    match: { path },
  } = props;
  return (
    <ErrorBoundary identifier="sales">
      <Switch>
        <Route
          exact
          path={`${path}`}
          render={() => (
            <LazyComponent>
              <Sales />
            </LazyComponent>
          )}
        />

        <Route
          exact
          path={`${path}/${toKebabCase(RMSModules.SALES_ALL)}`}
          render={() => (
            <LazyComponent>
              <RMSPage
                subscription={RMSSubscriptions.BASE}
                title={<Translate id="View_Sales_Title" />}
                url={`modules/sales/${RMSModules.SALES_ALL}`}
                module={`sales/${RMSModules.SALES_ALL}`}
                includePropertyIdInUrl
              />
            </LazyComponent>
          )}
        />

        <Route
          exact
          path={`${path}/${toKebabCase(RMSModules.SALES_CANCELLED)}`}
          render={() => (
            <LazyComponent>
              <RMSPage
                subscription={RMSSubscriptions.BASE}
                title={<Translate id="View_Cancelled_Sales_Title" />}
                url={`modules/sales/${RMSModules.SALES_CANCELLED}`}
                module={`sales/${RMSModules.SALES_CANCELLED}`}
                includePropertyIdInUrl
              />
            </LazyComponent>
          )}
        />
        <Route
          exact
          path={`${path}/${toKebabCase(RMSModules.SALES_PROJECTIONS)}`}
          render={() => (
            <LazyComponent>
              <RMSPage
                subscription={RMSSubscriptions.BASE}
                title={<Translate id="Sales_Projections_Title" />}
                url={`modules/sales/${RMSModules.SALES_PROJECTIONS}`}
                module={`sales/${RMSModules.SALES_PROJECTIONS}`}
                includePropertyIdInUrl
              />
            </LazyComponent>
          )}
        />

        <Route
          exact
          path={`${path}/${toKebabCase(RMSModules.SALES_STOREFRONT_COMMISSIONS)}`}
          render={() => (
            <LazyComponent>
              <RMSPage
                subscription={RMSSubscriptions.BASE}
                title={<Translate id="Storefront_Commissions_Title" />}
                url={`modules/sales/${RMSModules.SALES_STOREFRONT_COMMISSIONS}`}
                module={`sales/${RMSModules.SALES_STOREFRONT_COMMISSIONS}`}
                includePropertyIdInUrl
              />
            </LazyComponent>
          )}
        />

        <Route
          exact
          path={`${path}/${toKebabCase(RMSModules.SALES_DRIVER)}`}
          render={() => (
            <LazyComponent>
              <RMSPage
                subscription={RMSSubscriptions.BASE}
                title={<Translate id="Driver_Sales_Title" />}
                url={`modules/sales/${RMSModules.SALES_DRIVER}`}
                module={`sales/${RMSModules.SALES_DRIVER}`}
                includePropertyIdInUrl
              />
            </LazyComponent>
          )}
        />
      </Switch>
    </ErrorBoundary>
  );
};

export default SalesRoutes;
