import { CreateTeammate, TeammateBase, TeammatesApiFactory } from '@flipdish/api-client-typescript';

import { ALL_STORES_KEY } from '../components/Teammates/constants';
import { createApi } from '../helpers/utilities';
import { mapServerError } from './utils/serverErrorMapper';

const teammatesApi = createApi(TeammatesApiFactory);

export const teammatesService = {
  getTeammate,
  getTeammates,
  deleteTeammate,
  createTeammate,
  updateTeammate,
  grantTeammateAccess,
};

// #region loadDetails
export async function getTeammate(appId: string, teammateId: string) {
  try {
    const incomingMessage = await teammatesApi.getTeammateByAppIdAndTeammateId(appId, teammateId);
    return incomingMessage.Data;
  } catch (incomingMessage) {
    const err = await mapServerError(incomingMessage);
    throw err;
  }
}
// #endregion

// #region getAllTeammates
export async function getTeammates(appId: string) {
  try {
    const incomingMessage = await teammatesApi.getTeammatesByAppId(appId);
    return incomingMessage.Data;
  } catch (incomingMessage) {
    const err = await mapServerError(incomingMessage);
    throw err;
  }
}
// #endregion

// #region deleteTeammate
export async function deleteTeammate(appId: string, teammateId: string) {
  try {
    const incomingMessage = await teammatesApi.deleteTeammate(appId, teammateId);
    return incomingMessage;
  } catch (incomingMessage) {
    const err = await mapServerError(incomingMessage);
    throw err;
  }
}
// #endregion

// #region createTeammate
export async function createTeammate(
  appId: string,
  email: string,
  appAccessLevel: TeammateBase.AppAccessLevelEnum,
  storeIds: number[]
) {
  try {
    const { Owner, ManagedOwner } = TeammateBase.AppAccessLevelEnum;

    const teammate: CreateTeammate = {};
    teammate.AppAccessLevel = appAccessLevel;
    teammate.Email = email;

    const userHasAccessToAllStores =
      [Owner, ManagedOwner].includes(appAccessLevel) ||
      storeIds?.filter((id) => (id as unknown as string) !== ALL_STORES_KEY).length === 0;

    if (userHasAccessToAllStores) {
      teammate.HasAccessToAllStores = true;
      teammate.StoreIds = [];
    } else {
      teammate.HasAccessToAllStores = false;
      teammate.StoreIds = storeIds;
    }

    const incomingMessage = await teammatesApi.createTeammate(appId, teammate);
    return incomingMessage.Data;
  } catch (incomingMessage) {
    const err = await mapServerError(incomingMessage);
    throw err;
  }
}
// #endregion

// #region updateTeammateDetails
export async function updateTeammate(
  appId: string,
  teammateId: string,
  appAccessLevel: TeammateBase.AppAccessLevelEnum,
  hasAccessToAllStores: boolean,
  storeIds: number[]
) {
  try {
    const { Owner, ManagedOwner } = TeammateBase.AppAccessLevelEnum;

    const teammate: TeammateBase = {};
    teammate.AppAccessLevel = appAccessLevel;

    const userHasAccessToAllStores =
      [Owner, ManagedOwner].includes(appAccessLevel) ||
      (hasAccessToAllStores &&
        storeIds?.filter((id) => (id as unknown as string) !== ALL_STORES_KEY).length === 0);

    if (userHasAccessToAllStores) {
      teammate.HasAccessToAllStores = true;
      teammate.StoreIds = [];
    } else {
      teammate.HasAccessToAllStores = false;
      teammate.StoreIds = storeIds;
    }

    const incomingMessage = await teammatesApi.updateTeammate(appId, teammateId, teammate);
    return incomingMessage.Data;
  } catch (incomingMessage) {
    const err = await mapServerError(incomingMessage);
    throw err;
  }
}
// #endregion

export async function grantTeammateAccess(
  appId: string,
  email: string,
  appAccessLevel: TeammateBase.AppAccessLevelEnum,
  storeIds: number[]
) {
  try {
    const { Owner, ManagedOwner } = TeammateBase.AppAccessLevelEnum;

    const teammate: CreateTeammate = {};
    teammate.AppAccessLevel = appAccessLevel;
    teammate.Email = email;

    const userHasAccessToAllStores =
      [Owner, ManagedOwner].includes(appAccessLevel) ||
      storeIds?.filter((id) => (id as unknown as string) !== ALL_STORES_KEY).length === 0;

    if (userHasAccessToAllStores) {
      teammate.HasAccessToAllStores = true;
      teammate.StoreIds = [];
    } else {
      teammate.HasAccessToAllStores = false;
      teammate.StoreIds = storeIds;
    }

    const response = await teammatesApi.grantaccess(appId, teammate);
    return response.Data;
  } catch (incomingMessage) {
    const err = await mapServerError(incomingMessage);
    throw err;
  }
}
