import axios from 'axios';

import { getApiEndPoint } from '../../helpers/apibase';
import { mapServerError } from '../../services/utils/serverErrorMapper';

const baseURL = getApiEndPoint();

const orgManagementApi = axios.create({
  baseURL: `${baseURL}/orgManagement/orgs`,
  withCredentials: true,
});

// #region getAllPropertiesForOrg
export const getAllPropertiesForOrgKey = 'getAllPropertiesForOrg';
export async function getAllPropertiesForOrg(orgId): Promise<any> {
  try {
    const result = await orgManagementApi.get(`${orgId}/properties`);
    return result.data.data;
  } catch (e) {
    const err = await mapServerError(e);
    throw err;
  }
}
// #endregion

// #region createPropertyForOrg
export const createPropertyForOrgKey = 'createPropertyForOrg';
export async function createPropertyForOrg(orgId): Promise<any> {
  try {
    return await orgManagementApi.post(`${orgId}/properties`);
  } catch (e) {
    const err = await mapServerError(e);
    throw err;
  }
}
// #endregion

// #region getPropertyById
export const getPropertyByIdKey = 'getPropertyById';
export async function getPropertyById(orgId, propertyId): Promise<any> {
  try {
    return await orgManagementApi.get(`${orgId}/properties/${propertyId}`);
  } catch (e) {
    const err = await mapServerError(e);
    throw err;
  }
}
// #endregion

// #region updatePropertyById
export const updatePropertyByIdKey = 'updatePropertyById';
export async function updatePropertyById(orgId, propertyId): Promise<any> {
  try {
    return await orgManagementApi.post(`${orgId}/properties/${propertyId}`);
  } catch (e) {
    const err = await mapServerError(e);
    throw err;
  }
}
// #endregion

const propertiesService = {
  getAllPropertiesForOrg,
  createPropertyForOrg,
  updatePropertyById,
  getPropertyById,
};

export default propertiesService;
